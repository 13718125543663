import React from 'react'
import { useNavigate } from 'react-router-dom'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useState } from 'react';
import { InfrastructureSlide } from './MultiCarousel';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

 
const Science = [
    `${require('../images/infrastructur/infrastructur1.jpg')}`,
    `${require('../images/infrastructur/infrastructur2.jpg')}`,
    `${require('../images/infrastructur/infrastructur3.jpg')}`,
    `${require('../images/infrastructur/infrastructur4.jpg')}`,  

]
const Computer  = [
    `${require('../images/infrastructur/infrastructur5.jpg')}`,
    `${require('../images/infrastructur/infrastructur6.jpg')}`,
]
const LibraryImg = [
    `${require('../images/infrastructur/infrastructur7.jpg')}`,
    `${require('../images/infrastructur/infrastructur8.jpg')}`,
] 
const multyImg = [
    `${require('../images/infrastructur/infrastructur9.jpg')}`,
    `${require('../images/infrastructur/infrastructur8.jpg')}`,
    `${require('../images/infrastructur/infrastructur10.jpg')}`,  
] 
const room= [
    `${require('../images/infrastructur/infrastructur10.jpg')}`,
    `${require('../images/infrastructur/infrastructur12.jpg')}`,
    `${require('../images/infrastructur/infrastructur13.jpg')}`,  
]
const Assembly = [
    `${require('../images/infrastructur/infrastructur14.jpg')}`,
    `${require('../images/infrastructur/infrastructur17.jpg')}`,
]
const Pantryimg = [
    `${require('../images/infrastructur/pantry1.jpg')}`,
    // `${require('../images/infrastructur/infrastructur15.JPG')}`,
]
const Creative  = [
    `${require('../images/infrastructur/infrastructur9.jpg')}`,
    `${require('../images/infrastructur/infrastructur10.jpg')}`,
    `${require('../images/infrastructur/infrastructur11.jpg')}`,
    `${require('../images/infrastructur/infrastructur12.jpg')}`,  

]
const InfirmaryImg = [
    `${require('../images/infrastructur/infrastructur17.jpg')}`,
    `${require('../images/infrastructur/infrastructur14.jpg')}`,
    `${require('../images/infrastructur/infrastructur13.jpg')}`,  
] 

export const ScienceLaboratories=()=>{

    const navigate = useNavigate()
    return(
        <div class='row' >
        <div class='col-lg-6 col-12 '>
            <div class='text-start m-auto mt-5'>
                <div class='col-11 m-auto'> 
                    <p class="fw-bold fs-4"style={{color:'#F37335'}}>Science Laboratories</p>
                    {/* <p class='display-3 fw-bold my-3'>  <span class='text-success' > Chemicals </span> and the world around.</p> */}

                    <p style={{color:'gray'}} class=''>The laboratories are well equipped to support the learning of science to inculcate scientific temper in students. Experimentation and investigative skills are taught and encouraged under the supervision and guidance of experienced teachers. The school ensures high standards of safety in its laboratories. </p> 
                    <button class='btn my-3 fs-5' id='button' onClick={()=>navigate('/admission')} style={{width:'fit-content'}}><span ><i class="bi bi-arrow-right-circle-fill"></i></span><span class=' mx-2 m-0'>Admissions</span></button>
                     
                </div>
            </div>
        </div>
        <div class='col-lg-6 col-12 p-3'>
         <InfrastructureSlide images={Science}/> 
        </div>  
    </div>
    ) 
}

 
export const ComputerLaboratory=()=>{
    const navigate = useNavigate()

    return(
        <div class='row'>
        <div class='col-lg-6 col-12 '>
            <div class='text-start m-auto mt-5'>
                <div class='col-11 m-auto'> 
                    <p class="fw-bold fs-4"style={{color:'#F37335'}}>Computer Laboratory</p>
                    {/* <p class='display-3 fw-bold my-3'>Empowering Learning with <span class='text-success' > Technology.</span></p> */}

                    <p style={{color:'gray'}} class=''>To keep the students abreast with the Information Technology, the computer laboratory has been set up. Adequate number of computer hardware are installed in the Computer laboratory. The lab has facility of WIFI zone, powered by LAN connectivity.   </p> 
                    <button class='btn my-3 fs-5' id='button' onClick={()=>navigate('/admission')} style={{width:'fit-content'}}><span ><i class="bi bi-arrow-right-circle-fill"></i></span><span class=' mx-2 m-0'>Admissions</span></button>
                    

                </div>
            </div>
        </div>
        <div class='col-lg-6 col-12 p-3'>
                 <InfrastructureSlide images={Computer}/>  
        </div>  
    </div>
    ) 
}

export const Library=()=>{
    const navigate = useNavigate()

    return(
        <div class='row'>
        <div class='col-lg-6 col-12 '>
            <div class='text-start m-auto mt-5'>
                <div class='col-11 m-auto'> 
                    <p class="fw-bold fs-4"style={{color:'#F37335'}}>Library</p>
                    {/* <p class='display-3 fw-bold my-3'> Balance of  <span class='text-success' >Tradition</span>  and  <span class='text-success' >Modernization.</span>   </p> */}

                    <p style={{color:'gray'}} class=''>The school Library houses a good collection of books, magazines, periodicals and audio-visual materials. Such facility caters to the needs of the children of varying age groups and makes them aware of more information over and above the classroom learning. The ambience has been so created which makes the children feel comfortable and makes their reading enjoyable.   </p>
                    <button class='btn my-3 fs-5' id='button' onClick={()=>navigate('/admission')} style={{width:'fit-content'}}><span ><i class="bi bi-arrow-right-circle-fill"></i></span><span class=' mx-2 m-0'>Admissions</span></button>
                    

                </div>
            </div>
        </div>
        <div class='col-lg-6 col-12 p-3'>
                 <InfrastructureSlide images={LibraryImg}/> 

        </div>  
    </div>
    ) 
}

export const MultiPurposeArea=()=>{
    const navigate = useNavigate()
    
    return(
        <div class='row'>
        <div class='col-lg-6 col-12 '>
            <div class='text-start m-auto mt-5'>
                <div class='col-11 m-auto'> 
                    <p class="fw-bold fs-4"style={{color:'#F37335'}}>Multi-purpose area</p>
                    {/* <p class='display-3 fw-bold my-3'>  <span class='text-success' >Activity </span> Hub for All  </p> */}
                    <p style={{color:'gray'}} class=''>The multi-purpose area provides space for several activities and cultural programmes, at times. This area is used for indoor games during monsoon. Usually the dance classes are held there.  </p>
                    <button class='btn my-3 fs-5' id='button' onClick={()=>navigate('/admission')} style={{width:'fit-content'}}><span ><i class="bi bi-arrow-right-circle-fill"></i></span><span class=' mx-2 m-0'>Admissions</span></button>
                    

                </div>
            </div>
        </div>
        <div class='col-lg-6 col-12 p-3'>
                 <InfrastructureSlide images={multyImg}/> 

        </div>  
    </div>
    ) 
}

export const AVroom=()=>{
    const navigate = useNavigate()

    return(
        <div class='row'>
        <div class='col-lg-6 col-12 '>
            <div class='text-start m-auto mt-5'>
                <div class='col-11 m-auto'> 
                    <p class="fw-bold fs-4"style={{color:'#F37335'}}>AV room</p>

                    {/* <p class='display-3 fw-bold my-3'>  Step into <span class='text-success' >Digital Learning</span>  </p> */}
                    <p style={{color:'gray'}} class=''>Interactive learning sessions are conducted in the ‘Audio-Visual’ room, equipped with LCD Projector and a screen. Learning through digital media is performed using google chrome. The school conducts meetings and holds training sessions in this AV room for the teachers.  </p>
                    <button class='btn my-3 fs-5' id='button' onClick={()=>navigate('/admission')} style={{width:'fit-content'}}><span ><i class="bi bi-arrow-right-circle-fill"></i></span><span class=' mx-2 m-0'>Admissions</span></button>
                    

                </div>
            </div>
        </div>
        <div class='col-lg-6 col-12 p-3'>
                 <InfrastructureSlide images={room}/> 

        </div>  
    </div>
    ) 
}

export const AssemblyArea=()=>{
    const navigate = useNavigate()

    return(
        <div class='row'>
        <div class='col-lg-6 col-12 '>
            <div class='text-start m-auto mt-5'>
                <div class='col-11 m-auto'> 
                    <p class="fw-bold fs-4"style={{color:'#F37335'}}>Assembly Area</p>

                    {/* <p class='display-3 fw-bold my-3'>From <span class='text-success' >Assembly  </span>to Achievement </p> */}
                    <p style={{color:'gray'}} class=''> The school has an open area in the center portion of the building with a stage at one end. The day starts with the morning assembly in this area. At morning assembly, the students gather in the large and spacious assembly area where they conduct the morning’s proceedings under the guidance of the teachers.  </p>
                     <br />
                     <p style={{color:'gray'}} class=''>This open area is also meant for conducting various co-curricular activities such as Yoga, Karate and at times for indoor games. All the festivals and other events are also held in this space. General Parent-Teacher meetings are also conducted in this specific area.  </p>
                    <button class='btn my-3 fs-5' id='button' onClick={()=>navigate('/admission')} style={{width:'fit-content'}}><span ><i class="bi bi-arrow-right-circle-fill"></i></span><span class=' mx-2 m-0'>Admissions</span></button>
                    

                </div>
            </div>
        </div>
        <div class='col-lg-6 col-12 p-3'>
                 <InfrastructureSlide images={Assembly}/> 

        </div>  
    </div>
    ) 
}

export const Pantry=()=>{
    const navigate = useNavigate()

    return(
        <div class='row'>
        <div class='col-lg-6 col-12 '>
            <div class='text-start m-auto mt-5'>
                <div class='col-11 m-auto'> 
                    <p class="fw-bold fs-4"style={{color:'#F37335'}}>Pantry</p>

                     {/* <p class='display-3 fw-bold my-3'>Nutrition for <span class='text-success' >Healthy Minds  </span>  </p> */}
                    

                    <p style={{color:'gray'}} class=''>In keeping with the proverb “A healthy mind in a healthy body”, the school aims at providing wholesome, nutritious vegetarian meal to the students. Such food is prepared in a strictly hygienically maintained pantry by a team of skilled and experienced kitchen staff who serves the children with love and care. This rather prompts the children to wait anxiously for their ‘lunch break’.  </p>

                    <button class='btn my-3 fs-5' id='button' onClick={()=>navigate('/admission')} style={{width:'fit-content'}}><span ><i class="bi bi-arrow-right-circle-fill"></i></span><span class=' mx-2 m-0'>Admissions</span></button>
                    

                </div>
            </div>
        </div>
        <div class='col-lg-6 col-12 p-3'>
                 <InfrastructureSlide images={Pantryimg}/> 

        </div>  
    </div>
    ) 
}

export const CreativeCorner=()=>{
    const navigate = useNavigate()

    return(
        <div class='row'>
        <div class='col-lg-6 col-12 '>
            <div class='text-start m-auto mt-5'>
                <div class='col-11 m-auto'> 
                    <p class="fw-bold fs-4"style={{color:'#F37335'}}>Creative Corner</p>

                    {/* <p class='display-3 fw-bold my-3'>   <span class='text-success' >Creativity </span> at its Best </p> */}
                    <p style={{color:'gray'}} class=''>The Art, Craft and Music classes are held for the students in this ‘Creative Corner’. They have low seating arrangement in this room. The creative works of the students are displayed on the four walls of the room, making the room look appealing. </p>

                    <button class='btn my-3 fs-5' id='button' onClick={()=>navigate('/admission')} style={{width:'fit-content'}}><span ><i class="bi bi-arrow-right-circle-fill"></i></span><span class=' mx-2 m-0'>Admissions</span></button>
                    

                </div>
            </div>
        </div>
        <div class='col-lg-6 col-12 p-3'>
                 <InfrastructureSlide images={Creative}/> 

        </div>  
    </div>
    ) 
}

export const Infirmary=()=>{
    const navigate = useNavigate()

    return(
        <div class='row'>
        <div class='col-lg-6 col-12 '>
            <div class='text-start m-auto mt-5'>
                <div class='col-11 m-auto'> 
                    <p class="fw-bold fs-4"style={{color:'#F37335'}}>Infirmary</p>

                    {/* <p class='display-3 fw-bold my-3'>Caring for  <span class='text-success' >Students  </span>Well-being  </p> */}
                    <p style={{color:'gray'}} class=''>The school has an infirmary with a bed and first-aid kit. Students who suddenly fall ill or are accidentally injured seek refuge there and are given ‘first-aid’.  </p>
                    <button class='btn my-3 fs-5' id='button' onClick={()=>navigate('/admission')} style={{width:'fit-content'}}><span ><i class="bi bi-arrow-right-circle-fill"></i></span><span class=' mx-2 m-0'>Admissions</span></button>
                    

                </div>
            </div>
        </div>
        <div class='col-lg-6 col-12 p-3'>
                 <InfrastructureSlide images={InfirmaryImg}/> 

        </div>  
    </div>
    ) 
}

const Infrastructure = () => {
    const navigate = useNavigate()
    const [srishtiClass , setSrishtiClass] = useState("Science Laboratories")

    useEffect(()=>{
        AOS.init({duration: 1500}); 
    })
  return (
    <div>
        <div data-aos="zoom-in-up" class='d-grid align-items-center justify-content-center p-5' style={{backgroundImage:`url(${require("../images/about/top.png")})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',}} >
            <div>
                <p class='display-3 fw-bold'>Infrastructure</p>
                <p class='fw-semibold'> <span onClick={()=>navigate("/")} style={{cursor:'pointer'}} >Home</span> <span>|</span> <span onClick={()=>navigate("/infrastructure")} style={{cursor:'pointer'}}>Infrastructure</span> </p>
            </div>
        </div>
        <div style={{marginLeft:'50px'}}>
            <div class='infraBtnDiv'   >
                <div class='text-nowrap infraBtn'style={srishtiClass == "Science Laboratories" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('Science Laboratories')} >Science Laboratories</div>
                <div class='text-nowrap infraBtn'style={srishtiClass == "Computer Laboratory" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('Computer Laboratory')} >Computer Laboratory</div> 
                <div class='text-nowrap infraBtn'style={srishtiClass == "Library" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('Library')} >Library</div>
                <div class='text-nowrap infraBtn'style={srishtiClass == "Multi-purpose area" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('Multi-purpose area')} >Multi-purpose area</div>
                <div class='text-nowrap infraBtn'style={srishtiClass == "AV room" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('AV room')} >AV room</div>
                <div class='text-nowrap infraBtn'style={srishtiClass == "Assembly Area" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('Assembly Area')} >Assembly Area</div>
                <div class='text-nowrap infraBtn'style={srishtiClass == "Pantry" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('Pantry')} >Pantry</div> 
                <div class='text-nowrap infraBtn'style={srishtiClass == "Creative Corner" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('Creative Corner')} >Creative Corner</div>
                <div class='text-nowrap infraBtn'style={srishtiClass == "Infirmary" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('Infirmary')} >Infirmary</div> 
            </div> 
            {
                srishtiClass == "Science Laboratories" ? ScienceLaboratories() : ''
            }
            {
                srishtiClass == "Computer Laboratory" ? ComputerLaboratory() : ''
            }
            {
                srishtiClass == "Library" ? Library()  : ''
            }
            {
                srishtiClass == "Multi-purpose area" ?  MultiPurposeArea() : ''
            }
            {
                srishtiClass == "AV room" ? AVroom()  : ''
            }
            {
                srishtiClass == "Assembly Area" ? AssemblyArea()  : ''
            }
            {
                srishtiClass == "Pantry" ? Pantry()  : ''
            }
            {
                srishtiClass == "Creative Corner" ? CreativeCorner()  : ''
            }
            {
                srishtiClass == "Infirmary" ? Infirmary()  : ''
            }
            <br />
            <br />
        </div>


         <div style={{background:'#155799'}}>
            <br />
            <br />
         </div>
    </div>
  )
}

export default Infrastructure