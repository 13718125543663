import React, { useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Uniform } from './MultiCarousel';
import axios from 'axios';
import Swal from 'sweetalert2';

const admission = [
    {
        title:'Age criteria',
        text :[
            'The child should have completed 3 years of age as on 1st June for Beginners (i.e. Nursery).',
            'It is a requirement for a child to have completed 6 years of age as on 1st June for Class I.'
        ]
    }, 


    // {
    //     title:'Fee Structure',
    //     text :[
    //         'The child should have completed 3 years of age as on 1st June for Beginners (i.e. Nursery).',
    //         'It is a requirement for a child to have completed 6 years of age as on 1st June for Class I.'
    //     ]
    // },
    {
        title:'Admission procedure',
        text :[
            'The child should have completed 3 years of age as on 1st June for Beginners (i.e. Nursery).',
            'It is a requirement for a child to have completed 6 years of age as on 1st June for Class I.'
        ]
    }
]

const table =[
    {
        class :'Beginners',
        amount:'9720.00'
    },
    {
        class :'Jr. Kg',
        amount:'11520.00'
    },
    {
        class :'Sr. Kg',
        amount:'11520.00'
    },
    {
        class :'Class I to VIII',
        amount:'11520.00'
    },
    {
        class :'Class IX to X',
        amount:'13200.00'
    },
    {
        class :'Class XI & XII (Commerce)',
        amount:'12450.00'
    },
    {
        class :'Class XI & XII (science)',
        amount:'15000.00'
    }, 
]

const kinderUniform = [ 
        `${require('../images/admission/dress1.png')}`,
        `${require('../images/admission/dress1.png')}`,  
        `${require('../images/admission/dress1.png')}`,    
]

const kinderUniformVinter = [ 
    `${require('../images/admission/dres2.png')}`,
    `${require('../images/admission/dres2.png')}`,  
    `${require('../images/admission/dres2.png')}`,    
]

const higherUniform = [ 
    `${require('../images/infrastructur/infrastructur17.jpg')}`,
    `${require('../images/infrastructur/infrastructur13.jpg')}`,
]
const higherUniformwWednesday = [ 
    `${require('../images/infrastructur/infrastructur17.jpg')}`,
    `${require('../images/infrastructur/infrastructur13.jpg')}`,  
]
const higherUniformVineter = [ 
    `${require('../images/infrastructur/infrastructur17.jpg')}`,
    `${require('../images/infrastructur/infrastructur13.jpg')}`,  
]

const Admission = () => {
    const [contact,setContact]= useState({})
    const [emailValid , setEmailValid] = useState(false)

    const navigate = useNavigate()  

    const [uniformCode,setUniformCode] = useState('kindergarten')
 
    useEffect(()=>{
        AOS.init({duration: 1500}); 
    })

    const contactInfo = (e)=>{
        const {name,value}= e.target;
        setContact({...contact,[name]:value})
    }
    // console.log(contact);

    useEffect(()=>{
        if(contact?.Email?.includes('@')){
            setEmailValid(true)
        }else{
            setEmailValid(false)
        }
    },[contact])

    const sendInfo =()=>{
        if(contact?.Name && contact?.Name != "" &&
        contact?.Phone && contact?.Phone != "" &&
        contact?.Email && contact?.Email != "" &&
        contact?.Message && contact?.Message != ""&&
        contact?.Class && contact?.Class != ""){
        if(emailValid){ 
                axios.post(`https://techelecon.net/shristiapi/EnquiryNow.php`,contact)
                .then((res)=>{
                    // console.log(res);
                    if(res.data.responseStatus == true){
                        Swal.fire({
                          position: "center",
                          icon: "success",
                          title: `${res.data.responseMessage}`,
                          showConfirmButton: false,
                          timer: 2000,
                        })
                        setContact({
                            "Name" : '',
                            'Phone':'',
                            'Email': '',
                            'Message':'',
                            'Class':''
    
                        })
                    }else{
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: `${res.data.responseMessage}`,
                            showConfirmButton: false,
                            timer: 2000,
                          })
                    }
                }).catch((err)=>{
                    console.log(err);
                })
            }else{
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: `Invalid email address (required @)`, 
                    showConfirmButton: false,
                    timer: 2000,
                  })
            }
        }else{
            Swal.fire({
                position: "center",
                icon: "error",
                title: `Fill all fields`,
                showConfirmButton: false,
                timer: 2000,
              })
        }
    }

  return (
    <div > 

        <div data-aos="zoom-in-up" class='d-grid align-items-center justify-content-center p-5' style={{backgroundImage:`url(${require("../images/about/top.png")})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',}} >
            <div>
                <p class='display-3 fw-bold '>Admissions</p>
                <p class='fw-semibold'> <span onClick={()=>navigate("/")} style={{cursor:'pointer'}} >Home</span> <span>|</span> <span onClick={()=>navigate("/admission")} style={{cursor:'pointer'}}>Admissions</span> </p>
            </div>
        </div>

        <div  style={{background:'linear-gradient(#F2F7FD 50%,#ffffff 50%)'}}>
            <div data-aos="zoom-in-up" class='col-11 m-auto'  style={{height:'60vh', borderRadius:'15px'}} >
                <img style={{height:'100%',width:'100%',objectFit:'cover',borderRadius:'15px'}} src={require('../images/infrastructur/infrastructur13.jpg')} alt="" />
            </div>
        </div>

        <div class='row  col-11 m-auto flex-column-reverse flex-lg-row '> 
            <div class='col-lg-6  my-5'  >
                <div class='col-lg-11 col-12 text-start'> 
                    <p class='fs-1 fw-bold mb-5'>Admission</p>   
                    {/* {admission.map((el,i)=>{
                        return(
                            <div class='  mt-4 rounded' style={{background:'#F3F9FF'}}>
                                <div class='d-flex justify-content-between fs-5 fw-bold p-2 rounded' data-bs-toggle="collapse" href={`#admissionDetails${i}`} role="button" aria-expanded="false" aria-controls="collapseExample"   style={{background:'#155799',color:'white'}}>
                                    <p>{el.title}</p>
                                    <p><i class="bi bi-chevron-down"></i></p>
                                </div>
                                <div class="collapse" id={`admissionDetails${i}`}>
                                    <div class="text-start p-2 fs-5">
                                        <p>{el.text}</p>
                                    </div>
                                </div>   
                            </div>
                        )
                    })} */}
                      
                            <div class='  mt-4 rounded' style={{background:'#F3F9FF'}}>
                                <div class='d-flex justify-content-between fs-5 fw-bold p-2 rounded' data-bs-toggle="collapse" href={`#admissionDetails1`} role="button" aria-expanded="false" aria-controls="collapseExample"   style={{background:'#155799',color:'white'}}>
                                    <p>Age criteria</p>
                                    <p><i class="bi bi-chevron-down"></i></p>
                                </div>
                                <div class="collapse" id={`admissionDetails1`}>
                                    <div class="text-start p-2 fs-5">
                                        <p>The child should have completed 3 years of age as on 1st June for Beginners (i.e. Nursery).</p>
                                        <br />
                                        <p>It is a requirement for a child to have completed 6 years of age as on 1st June for Class I. </p>
                                    </div>
                                </div>   
                            </div>

                            <div class='  mt-4 rounded' style={{background:'#F3F9FF'}}>
                                <div class='d-flex justify-content-between fs-5 fw-bold p-2 rounded' data-bs-toggle="collapse" href={`#admissionDetails2`} role="button" aria-expanded="false" aria-controls="collapseExample"   style={{background:'#155799',color:'white'}}>
                                    <p>Admission procedure</p>
                                    <p><i class="bi bi-chevron-down"></i></p>
                                </div>
                                <div class="collapse" id={`admissionDetails2`}>
                                    <div class="text-start p-2 fs-5">
                                        <p>The parents need to fill up the Admission form available at school office and visit the school. Fee details will be given at the time of admission. Admissions are granted based on vacancies available. </p>
                                        <br />
                                        <p>The parent can fill the details provided in ‘Enquiry’ section on the school’s website, but have to collect the Admission form from school office and submit the same at the School on time. </p>
                                    </div>
                                    
                                    <div class='mt-5 bg-white'>
                                        <br />
                                        <p class='fs-1 fw-bold '>Admission procedure</p>
                                        <div class='row mt-3'>
                                            <div class='col-lg-6 col-12   p-3'>
                                                <div class='text-start p-3' style={{border: '0.5px solid #D7D7D7',boxShadow: '12px 16px 13px rgba(0, 0, 0, 0.09)',borderRadius: '0px 0px 7px 7px',minHeight:'370px'}}>
                                                    <div>
                                                        <img style={{width:'70px'}} src={require("../images/admission/icon1.png")} alt="" />
                                                    </div>
                                                    <div class='mt-4'>
                                                        <p class='fs-4 fw-bold'>Step 1</p>
                                                        <div class='my-3' style={{border:'2px solid green', width:'70px'}}></div>
                                                        <p>The parents need to fill up the Admission form available at school office and visit the school. Fee details will be given at the time of admission. Admissions are granted based on vacancies available.</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class='col-lg-6 col-12   p-3'>
                                                <div class='text-start p-3' style={{border: '0.5px solid #D7D7D7',boxShadow: '12px 16px 13px rgba(0, 0, 0, 0.09)',borderRadius: '0px 0px 7px 7px',minHeight:'370px'}}>
                                                    <div>
                                                        <img style={{width:'70px'}} src={require("../images/admission/icon2.png")} alt="" />
                                                    </div>
                                                    <div class='mt-4'>
                                                        <p class='fs-4 fw-bold'>Step 2</p>
                                                        <div class='my-3' style={{border:'2px solid red', width:'70px'}}></div>
                                                        <p>The parent can fill the details provided in ‘Enquiry’ section on the school’s website, but have to collect the Admission form from school office and submit the same at the School on time.</p>
                                                    </div> 
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>    
                            </div>
                     

                    {/* <div class='mt-5'>
                        <p class='fs-1 fw-bold'>Admission procedure</p>
                        <div class='row mt-3'>
                            <div class='col-lg-6 col-12   p-3'>
                                <div class='text-start p-3' style={{border: '0.5px solid #D7D7D7',boxShadow: '12px 16px 13px rgba(0, 0, 0, 0.09)',borderRadius: '0px 0px 7px 7px',minHeight:'370px'}}>
                                    <div>
                                        <img style={{width:'70px'}} src={require("../images/admission/icon1.png")} alt="" />
                                    </div>
                                    <div class='mt-4'>
                                        <p class='fs-4 fw-bold'>Step 1</p>
                                        <div class='my-3' style={{border:'2px solid green', width:'70px'}}></div>
                                        <p>The parents need to fill up the Admission form available at school office and visit the school. Fee details will be given at the time of admission. Admissions are granted based on vacancies available.</p>
                                    </div>

                                </div>
                            </div>
                            <div class='col-lg-6 col-12   p-3'>
                                <div class='text-start p-3' style={{border: '0.5px solid #D7D7D7',boxShadow: '12px 16px 13px rgba(0, 0, 0, 0.09)',borderRadius: '0px 0px 7px 7px',minHeight:'370px'}}>
                                    <div>
                                        <img style={{width:'70px'}} src={require("../images/admission/icon2.png")} alt="" />
                                    </div>
                                    <div class='mt-4'>
                                        <p class='fs-4 fw-bold'>Step 2</p>
                                        <div class='my-3' style={{border:'2px solid red', width:'70px'}}></div>
                                        <p>The parent can fill the details provided in ‘Enquiry’ section on the school’s website, but have to collect the Admission form from school office and submit the same at the School on time.</p>
                                    </div> 
                                </div>
                            </div>

                        </div>
                    </div> */}

                    <div class='text-start mt-5'>
                        <p class='fs-1 fw-bold'>Academic structure</p> 
                        <div class='my-4'> 
                            <div style={{width:'70px',border:'2px solid red'}}></div>
                            <p class='fw-bold fs-5 mt-2'>Curriculum</p>
                            <p class='mt-3' style={{fontSize:'16px',fontWeight:'500',maxWidth:'500px'}}>The School is affiliated to Gujarat Secondary Education Board. Hence, the school follows GSEB curriculum.</p>
                        </div>
                        <div class='my-4 mt-5'> 
                            <div style={{width:'70px',border:'2px solid red'}}></div>
                            <p class='fw-bold fs-5 mt-2'>Exam Calendar</p>
                            <div class='d-flex gap-3 mt-3 align-items-center'>
                                <p style={{textDecoration:'underline'}}>View Online</p>
                                {/* <button class='btn p-1 px-2 ' id='button' style={{width:'fit-content', }}><span ><i class="bi bi-arrow-right-circle-fill"></i></span><span class=' mx-2 m-0'>Join Now</span></button> */}
                            </div>
                        </div>
                        <div class='my-4 mt-5'> 
                            <div style={{width:'70px',border:'2px solid red'}}></div>
                            <p class='fw-bold fs-5 mt-2'>Fee Structure</p>

                            <div class='mt-5' style={{borderRadius:'10px 0 0 0'}}>
                                <p class='fw-bold p-2 px-3 text-white' style={{borderRadius:'10px 0 0 0',background:'#F37335'}}>Half yearly fees structure</p>
                            </div>
                            <table class="table table-bordered " style={{borderColor:'0.4px solid #8EA1B5',background:'#F3F9FF'}}>
                                <thead>
                                    <tr>
                                        <th scope="col">Class</th>
                                        <th scope="col">Amount (₹)</th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        table.map((el)=>{
                                            return(
                                                <tr>
                                                    <td>{el.class}</td>
                                                    <td>{el.amount}</td> 
                                                </tr>
                                            )
                                        })
                                    } 
                                </tbody>
                            </table>

                            <p class='fw-bold my-2 mt-4'>Note:-</p>
                            <ul class='fw-bold'>
                                <li>
                                    ₹ 4000/- at the time of admission for pre-primary & classes I to X.
                                </li>
                                <li>
                                    Admission fees is non-refundable.
                                </li>
                                <li>
                                    Food charges will be extra, 
                                </li>
                                <li>
                                    No school leaving certificate will be issued until all dues are cleared.
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div>
                        <p class='fs-1 fw-semibold mt-5'>Online Fee payment</p>
                        <p class='fs-1 fw-semibold my-3'><i>Content Yet to come</i></p>
                    </div>

                    <div class='mt-5'>
                        <p class='fs-1 fw-bold'>School uniform</p> 
                        <div class='d-flex gap-4 mt-4'>
                            <button class='fs-5 fw-bold p-2 px-4' onClick={()=>setUniformCode("kindergarten")} style={uniformCode == "kindergarten"?{background:'#F37335',border:'none',color:'white'} :{background:'#F2F2F2',border:'none',color:'black'}}>Kindergarten</button>
                            <button class='fs-5 fw-bold p-2 px-4' onClick={()=>setUniformCode("classes1to10")} style={uniformCode == "classes1to10"?{background:'#F37335',border:'none',color:'white'} :{background:'#F2F2F2',border:'none',color:'black'}}>Classes I to XII</button>
                        </div>
                        <div class='mt-5'> 
                            {
                                uniformCode == "kindergarten" ?
                                <div>
                                    <div class='uniform'>
                                        <div class='col-12'>
                                            <div class='p-2 px-4 fw-bold fs-4 d-flex gap-3 align-items-center' style={{background:'#155799'}}>
                                                <p class='text-white'>Girls</p>
                                                <img style={{height:'28px'}} src={require("../images/admission/girl.png")} alt="" />
                                            </div>
                                            <div style={{background:'#F3F9FF',padding:'10px'}}>
                                                <ul class='m-2'>
                                                    <li>Pink T-shirt with school logo</li>
                                                    <li>Any colored skirt, shorts or pant</li>
                                                    <li>Black shoes and socks</li>
                                                    
                                                </ul>
                                            </div>
                                        </div>
                                        <div class='col-12'>
                                            <div class='p-2 px-4 fw-bold fs-4 d-flex gap-3 align-items-center' style={{background:'#155799'}}>
                                                <p class='text-white'>Boys</p>
                                                <img style={{height:'28px'}} src={require("../images/admission/boy.png")} alt="" />
                                            </div>
                                            <div style={{background:'#F3F9FF',padding:'10px'}}>
                                                <ul class='m-2'>
                                                    <li>Pink T-shirt with school logo</li>
                                                    <li>Any colored shorts or pant</li>
                                                    <li>Black shoes and socks</li>
                                                     
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class=''>
                                        <Uniform uniform={kinderUniform}/>
                                    </div>
                                    {/* <div class='p-2 px-4 fw-bold fs-4  text-center' style={{background:'#155799'}}>
                                        <p class='text-white'>Winter</p>
                                    </div> */}
                                    <div class='uniform'>
                                        <div style={{background:'#F3F9FF',padding:'10px'}}>
                                            <ul class='m-2'>
                                                <li>Maroon sweater</li>
                                            </ul>
                                        </div>
                                        <div style={{background:'#F3F9FF',padding:'10px'}}>
                                            <ul class='m-2'>
                                                <li>Maroon sweater</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class=''>
                                        <Uniform uniform={kinderUniformVinter}/>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div class='uniform'>
                                        <div class='col-12'>
                                            <div class='p-2 px-4 fw-bold fs-4 d-flex gap-3 align-items-center' style={{background:'#155799'}}>
                                                <p class='text-white'>Girls I to XII</p>
                                                <img style={{height:'28px'}} src={require("../images/admission/girl.png")} alt="" />
                                            </div>
                                            <div style={{background:'#F3F9FF',padding:'10px'}}>
                                                <ul class='m-2'>
                                                    <li>Maroon and white checked shirt</li>
                                                    <li>Dark grey skirt</li>
                                                    <li>Black shoes and black socks</li> 
                                                </ul>
                                            </div>
                                        </div>
                                        <div class='col-12'>
                                            <div class='p-2 px-4 fw-bold fs-4 d-flex gap-3 align-items-center' style={{background:'#155799'}}>
                                                <p class='text-white'>Boys I to XII</p>
                                                <img style={{height:'28px'}} src={require("../images/admission/boy.png")} alt="" />
                                            </div>
                                            <div style={{background:'#F3F9FF',padding:'10px'}}>
                                                <ul class='m-2'>
                                                    <li>Maroon and white checked shirt</li>
                                                    <li>Dark grey pant</li>
                                                    <li>Black shoes and black socks</li> 
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class=''>
                                        <Uniform uniform={higherUniform}/>
                                    </div>
                                    <div class='p-2 px-4 fw-bold fs-4  text-center' style={{background:'#155799'}}>
                                        <p class='text-white'>Wednesday and Saturday</p>
                                    </div>
                                    <div class='uniform'>
                                        <div style={{background:'#F3F9FF',padding:'10px'}}>
                                            <ul class='m-2'>
                                                <li>White T-shirt with school logo</li>
                                                <li>Navy blue track pant</li>
                                                <li>Black shoes and black socks</li> 
                                            </ul>
                                        </div>
                                        <div style={{background:'#F3F9FF',padding:'10px'}}>
                                            <ul class='m-2'>
                                                <li>White T-shirt with school logo</li>
                                                <li>Navy blue track pant</li> 
                                                <li>Black shoes and black socks</li> 
                                            </ul>
                                        </div>
                                    </div>
                                    <div class=''>
                                        <Uniform uniform={higherUniformwWednesday}/>
                                    </div>
                                    {/* <div class='p-2 px-4 fw-bold fs-4  text-center' style={{background:'#155799'}}>
                                        <p class='text-white'>Wednesday and Saturday</p>
                                    </div> */}
                                    <div class='uniform'>
                                        <div style={{background:'#F3F9FF',padding:'10px'}}>
                                            <ul class='m-2'>
                                                <li>Maroon Blazer/Sweater with school logo</li> 
                                            </ul>
                                        </div>
                                        <div style={{background:'#F3F9FF',padding:'10px'}}>
                                            <ul class='m-2'>
                                                <li>Maroon Blazer/Sweater with school logo</li> 
                                            </ul>
                                        </div>
                                    </div>
                                    <div class=''>
                                        <Uniform uniform={higherUniformVineter}/>
                                    </div>
                                </div>
                            }
                        </div>



                    </div>

                    <div>

                    </div>
                </div>
            </div>
            <div class='col-lg-6  my-5 enquirySection'    >
                <div class='col-12 m-auto p-3 rounded-4' style={{boxShadow:' 0px 9px 23px rgba(0, 0, 0, 0.1)'}} > 
                <p class='fs-1 fw-bold mb-5'>Enquiry Now</p>
                    <div class='row' >
                        <div class='col-6 p-2'>
                            <input type="text" class='form-control p-2 px-3 rounded-4' placeholder='Name' value={contact?.Name} name='Name' onChange={contactInfo} />
                        </div>
                        <div class='col-6 p-2 text-start' style={{position:'relative', top:'-22px'}}>
                            <level class='mx-2' >Class</level>
                             <select class="form-select rounded-4" aria-label="Default select example" value={contact?.Class}  name='Class' onChange={contactInfo} >
                                <option selected>Select </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>


                            </select>
                            {/* <input type="text" class='form-control p-2 px-3 rounded-4' placeholder='Name' /> */}
                        </div>
                        <div class='col-sm-6 col-12 p-2'>
                            <input type="text" class='form-control p-2 px-3 rounded-4' placeholder='Email'  name='Email' value={contact?.Email} onChange={contactInfo}  />
                        <label data-aos="fade-up" class='text-start text-danger px-2'  style={emailValid ? {display:'none'}:{marginTop:'0px',display:'block'}}  >Invalid email address (required @)</label>

                        </div> 
                        <div class='col-sm-6 col-12 p-2'>
                            <input type="number" class='form-control p-2 px-3 rounded-4' placeholder='Phone No.' value={contact?.Phone}  name='Phone' onChange={contactInfo}  />
                        </div>  
                    </div>
                    
                    <textarea class='form-control rounded-4 my-2 p-2 px-3' placeholder='Your Message' id="" cols="10" rows="4" value={contact?.Message}  name='Message' onChange={contactInfo} ></textarea>
                    <div class='d-flex justify-content-end my-4' >
                            <button  onClick={sendInfo} class='btn  ' id='button' style={{width:'fit-content'}}><span ><i class="bi bi-arrow-right-circle-fill"></i></span><span class=' mx-2 m-0'>Send Now</span></button>
                    </div>
                </div>
            </div>
        </div>  

        <div class='col-11 row m-auto'>
            <div class='col-lg-6 col-12 text-start'>
                <p class='fs-1 fw-bold'>Communication with Teachers</p>
                <p class=''>Parents desirous of meeting the teachers may seek prior appointment from the Receptionist. Parents of wards of classes I to VIII may preferably meet the Teachers, with prior appointment, on fourth Saturday.
                    The parents of ward studying in Kindergarten can meet the teachers on all working Saturdays.
                </p>
               
            </div>
            <div class='col-lg-6 col-12' >
                 <div class='col-lg-6 col-12'>
                    <img  style={{width:'100%'}} src={require("../images/admission/girlBg.png")} alt="" />
                 </div>
            </div>
            
        </div> 
 
    </div>
  )
}

export default Admission