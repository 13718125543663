import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


export const HomeTop = ({images})=>{
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1120 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1120, min: 850 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 850, min: 0 },
      items: 1
    }
  };
  return(
    <Carousel class='ourOfferingSlider' responsive={responsive} infinite={true} autoPlay={true}  autoPlaySpeed={3000} showDots={false}    >
         {images?.map((el)=>{
                  return( 
                        <div class='text-end'> 
                              <img class='homeTopImg'  data-aos="zoom-in"src={el.img} alt="" />
                          </div>
              )
          })} 
    </Carousel>
  )
}

export  const HomeCard =({cards})=>{
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 500 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 500, min: 0 },
          items: 1
        }
      };
      return(
        <Carousel class='' responsive={responsive} infinite={false} autoPlay={true}  autoPlaySpeed={3000} showDots={false}    removeArrowOnDeviceType={["tablet", "mobile",'desktop','superLargeDesktop']}>
             {
                    cards.map((el)=>{
                        return(
                              <a href={el.url}> 
                                <div class='col-11 rounded-3  homeTopCard' style={{marginLeft:'3px',cursor:'pointer'}}  >
                                    <div class='text-start p-3 px-4' style={{boxShadow: '0px 2.2356px 11.178px rgba(21, 87, 153, 0.08)'}}>
                                        {/* <img src={el.icon} alt="" /> */}
                                        <div class='HomeCards'> 
                                            {el.svg}
                                        </div>
                                        <p class='fw-bold py-3'>{el.title}</p>
                                        {/* <p>{el.text}</p>  */}
                                        {/* <br /> */}
                                    </div>
                                </div>
                              </a>
                        )
                    })
                }
        </Carousel>
      )
}
export default HomeCard
 


export const StudentAchiev =({stuAchiv})=>{
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1250 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1250, min: 850 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 850, min: 0 },
          items: 1
        }
      };
      return(
        <Carousel class='ourOfferingSlider' responsive={responsive} infinite={true} autoPlay={true}  autoPlaySpeed={3000} showDots={false}    >
             {
                    stuAchiv.map((el)=>{
                        return(
                            <div class='m-auto my-5 col-11' >  
                              <div class='row rounded-5  ' style={{padding:'12px 0',background:"linear-gradient(106.27deg, #30B74F -5.71%, #215395 79.18%)"}}>
                                <div class='col-sm-5 col-12'>
                                  <div class='col-md-12  m-auto bg-light  rounded-5'>
                                    <img class='py-4' src={el.icon} alt="" />
                                  </div> 
                                </div>
                                <div class='text-start col-sm-7 col-11'>
                                  <div> 
                                    <p class='fs-3 fw-semibold '><span class='text-white'>{el.studentName}</span> </p>
                                    <p class='fw-semibold p-1 px-3 rounded mb-2 ' style={{background:'#ffd31d', width:'fit-content', }}>{el.results}</p>
                              
                                  </div>
                                </div>
                              </div> 
                            </div>
                        )
                    })
                }
        </Carousel>
      )
}


export const NewsBlog =({newsBlog})=>{
  const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2
      },
      tablet: {
        breakpoint: { max: 1024, min: 750 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 750, min: 0 },
        items: 1
      }
    };
    return(
      <Carousel class='ourOfferingSlider' responsive={responsive} infinite={true} autoPlay={true}  autoPlaySpeed={3000} showDots={false}    removeArrowOnDeviceType={["tablet", "mobile",'desktop','superLargeDesktop']}>
           {
                  newsBlog.map((el)=>{
                      return(
                          <div class='p-2 text-start m-auto' >  
                              <div class=' ' >
                                  <img style={{width:'100%'}} src={el.img} alt="" />
                              </div>
                              <button class='btn rounded-5 p-0 px-3 mx-5' style={{background:'#FFD31D',marginTop:'-28px'}}>English</button>
                              <div class='d-flex justify-content-between p-2'>
                                  <p>{el.date}</p>
                                  <p>{el.hows}</p>
                                  <p><span style={{color:'#F37335'}}><i class="bi bi-tags-fill"></i></span> {el.publis}</p>
                              </div>
                              <div>
                                  <p class='fs-4 fw-bold'>{el.title}</p>
                                  <p class='mt-2'>{el.text}</p>
                              </div>

                              <p class='fw-bold my-3' style={{color:'#155799'}}>Read <span><i class="bi bi-arrow-right"></i></span></p>
                          </div>
                      )
                  })
              }
      </Carousel>
    )
}

export const Gallery =({gallery})=>{
  const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3
      },
      desktop: {
        breakpoint: { max: 3000, min: 1120 },
        items: 4
      },
      tablet: {
        breakpoint: { max: 1120, min: 850 },
        items: 3
      },
      mobile: {
        breakpoint: { max: 850, min: 0 },
        items: 1
      }
    };
    return(
      <Carousel class='ourOfferingSlider' responsive={responsive} infinite={true} autoPlay={true}  autoPlaySpeed={1500} showDots={false}    removeArrowOnDeviceType={["tablet", "mobile",'desktop','superLargeDesktop']}>
           {
                  gallery.map((el)=>{
                      return(
                          <div class='p-3' >  
                            <img style={{width:'100%' }}  src={el} alt="" />
                          </div>
                      )
                  })
              }
      </Carousel>
    )
}





export const OtherTrustees =({otherTrustees})=>{
  const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3
      },
      desktop: {
        breakpoint: { max: 3000, min: 1280 },
        items: 4
      },
      tablet: {
        breakpoint: { max: 1280, min: 950 },
        items: 3
      },
      mobile: {
        breakpoint: { max: 950, min: 0 },
        items: 1
      }
    };
    return(
      <Carousel class='ourOfferingSlider' responsive={responsive} infinite={false} autoPlay={true}  autoPlaySpeed={1500} showDots={false}     >
           {
                  otherTrustees.map((el)=>{
                      return(
                        <div >
                            <div >  
                              <img class='rounded-4' style={{width:'280px', height:'300px',objectFit:'cover'}}  src={el.img} alt="" />
                            </div>
                            <div>
                              <p class='fw-bold text-white'>{el.name}</p>
                              <p class='text-white'>{el.post}</p>
                            </div> 
                        </div>
                      )
                  })
              }
      </Carousel>
    )
}
 
 
export const InfrastructureSlide = ({images})=>{
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1120 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1120, min: 850 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 850, min: 0 },
      items: 1
    }
  };
  return(
    <Carousel class='ourOfferingSlider' responsive={responsive} infinite={true} autoPlay={true}  autoPlaySpeed={2000} showDots={true}    >
         {
                images.map((el)=>{
                    return(
                        <div class='p-3 rounded-5' >  
                          <img class='rounded-5' style={{width:'100%'}}  src={el} alt="" />
                        </div>
                    )
                })
            }
    </Carousel>
  )
}

export const Uniform = ({uniform})=>{
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1120 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1120, min: 850 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 850, min: 0 },
      items: 1
    }
  };
  return(
    <Carousel class='ourOfferingSlider' responsive={responsive} infinite={true} autoPlay={true}  autoPlaySpeed={2000} showDots={false}    removeArrowOnDeviceType={["tablet", "mobile",'desktop','superLargeDesktop']}>
         {
                uniform.map((el)=>{
                    return(
                        <div >  
                          <img style={{height:'50vh',width:'100%',objectFit:'cover',}}  src={el} alt="" />
                        </div>
                    )
                })
            }
    </Carousel>
  )
}


export const AlumniSpeaks = ({speak})=>{
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1120 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1120, min: 850 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 850, min: 0 },
      items: 1
    }
  };
  return(
    <Carousel  responsive={responsive} infinite={true} autoPlay={true}  autoPlaySpeed={2500} showDots={false}    removeArrowOnDeviceType={["tablet", "mobile",'desktop','superLargeDesktop']}>
         {
                speak.map((el)=>{
                    return(
                        <div class='col-11 my-2' >  
                            <p>{el.speak}</p>
                            <p class='fw-bold fs-5'>{el.name}</p>
                        </div>
                    )
                })
            }
    </Carousel>
  )
}