import React from "react";
import { useNavigate } from "react-router-dom";
import { BiPlusMedical } from "react-icons/bi";
import { GrFacebookOption, GrLinkedinOption } from "react-icons/gr";
import { AiOutlineInstagram } from "react-icons/ai";
import { useState } from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const table2 = [
  {
    fullName: "Mr.Sanjay Mistry",
    academicQualification: "M.SC. , B. Ed.",
    professionalQualification: "M.SC. , B. Ed.",
    post: "Principal",
  },
  {
    fullName: "MR. RIKESH M. SHELAT",
    academicQualification: "M. COM., MDCA., TFAP.,DBM.,",
    professionalQualification: "B. Ed.",
    post: "Asst. Teacher",
  },
  {
    fullName: "MR. BHAVIN M. MACWAN",
    academicQualification: "M. Sc. (Physics)",
    professionalQualification: "M. Ed.",
    post: "Asst. Teacher",
  },
  {
    fullName: "MR. SELVIN M. VAGHELA",
    academicQualification: "M. A. (Hindi)",
    professionalQualification: "B. Ed.",
    post: "Asst. Teacher",
  },
  {
    fullName: "MR. VISHAL M. PANDYA",
    academicQualification: "M. Com.,",
    professionalQualification: "B. Ed.,",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. VIDHI K. MISTRY",
    academicQualification: "M. Sc. (Maths)",
    professionalQualification: "B. Ed.",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. DIMPLE R. PATEL",
    academicQualification: "M. Com.,",
    professionalQualification: "B. Ed.,",
    post: "Asst. Teacher",
  },
  {
    fullName: "Heli Thakar",
    academicQualification: "M. Sc. (Maths)",
    professionalQualification: "B. Ed.,",
    post: "Asst. Teacher",
  },
  {
    fullName: "MR. HIREN R. PATEL",
    academicQualification: "M. Sc. (Chemistry)",
    professionalQualification: "M. Ed.,",
    post: "Asst. Teacher",
  },
  {
    fullName: "MR. RAJU V. MACWAN",
    academicQualification: "B. A.,",
    professionalQualification: "B. Ed.,",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. NAYANA J. Rathod",
    academicQualification: "B. A., PGDCA.,",
    professionalQualification: "B. Ed.,",
    post: "Asst. Teacher",
  },
  {
    fullName: "MR. BHAVESH A. PRAJAPATI",
    academicQualification: "M. A. (Gujarati)",
    professionalQualification:
      "B. P. Ed., Dipolma in Music, Karate 2nd Dar Black Belt",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. SUNITA B. RAO",
    academicQualification: "B. A.,",
    professionalQualification: "B. Ed.,",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. BHAVNA C. PARMAR",
    academicQualification: "M. A. (Sanskrit)",
    professionalQualification: "B. Ed.,",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS.PINKY V SHAH",
    academicQualification: "B. Com.,",
    professionalQualification: "B. Ed.,",
    post: "Asst. Teacher",
  },
  {
    fullName: "MR. YOGESH R. VASAVA",
    academicQualification: "HSc.,",
    professionalQualification: "ATD (fine Arts)",
    post: "Asst. Teacher",
  },
  {
    fullName: "Asst. Teacher",
    academicQualification: "M. A. (Economics)",
    professionalQualification: "B. P. Ed., Diploma in yoga",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. BHAVINI P. PATEL",
    academicQualification: "M. A. (English)",
    professionalQualification: "B. Ed., B. P. Ed.,",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. MADHURIKA CHAUHAN",
    academicQualification: "M. A. (Gujarati)",
    professionalQualification: "B. Ed.,",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. SWETA P. MACWAN",
    academicQualification: "M. A. (Gujarati)",
    professionalQualification: "B. Ed.,",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. SHILPA B. SONI",
    academicQualification: "M. A. (Hindi)",
    professionalQualification: "B. Ed.,",
    post: "Asst. Teacher",
  },
  {
    fullName: "MR. PARESH K. MACWAN",
    academicQualification: "B. P. A.,",
    professionalQualification:
      "Sangeet Visharad, Diploma in classical & Western Music (Guitar)",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. RASHMI M. CHAUHAN",
    academicQualification: "B. A.,",
    professionalQualification: "P. T. C.,",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. JINA C. PATEL",
    academicQualification: "M. Sc. (Physics) ",
    professionalQualification: "B.Ed., Dipolma in yoga",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. SNEHA SHARMA",
    academicQualification: "H. Sc., Dipolma in Electrical Eng., P.G.D.C.A",
    professionalQualification: "",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. PARITA V. SOLANKI",
    academicQualification: "M. A. (English)",
    professionalQualification: "B. Ed.,",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. POOJA BHATIA",
    academicQualification: "M. A. (English)",
    professionalQualification: "M. Ed.,",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. CHITRALEKHA MUKHREJEE",
    academicQualification: "H. Sc.,",
    professionalQualification: "Montessori",
    post: "Asst. Teacher",
  },
  {
    fullName: "Ms. Parul Raj",
    academicQualification: "B. Com., M. Com. (Pursuing)",
    professionalQualification: "D. C. A.,",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. KANCHAN KAHARATE",
    academicQualification: "H. Sc., Ms Office & Prog., Graduate Pursuing",
    professionalQualification: "Pre PTC",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. JAYATI MUKHERJEE",
    academicQualification: "B. A., B. A. (Music)",
    professionalQualification: "Diploma in Fine Arts",
    post: "Asst. Teacher",
  },
  {
    fullName: "MS. PURVIKA S. PRAJAPATI",
    academicQualification: "B. A.,",
    professionalQualification: "Montessori",
    post: "Asst. Teacher",
  },
  {
    fullName: "MR. UPENDRA RAJ SINH",
    academicQualification: "B. Com.,",
    professionalQualification: "",
    post: "Office Asst.",
  },
  {
    fullName: "MS. MONA MACWAN",
    academicQualification: "B. A.,",
    professionalQualification: "",
    post: "Office Asst.",
  },
];

const tableData = [
  {
    fullName: "Full Name",
    education: "Education",
    student: "Student",
  },
  {
    fullName: "Full Name",
    education: "Education",
    student: "Student",
  },
  {
    fullName: "Full Name",
    education: "Education",
    student: "Student",
  },
  {
    fullName: "Full Name",
    education: "Education",
    student: "Student",
  },
  {
    fullName: "Full Name",
    education: "Education",
    student: "Student",
  },
];

const table = (table) => {
  return (
    <table
      class="table table-bordered text-white my-5"
      style={{ borderColor: "#5289BF" }}
    >
      <thead style={{ position: "sticky", top: "0" }}>
        <tr>
          <th scope="col" class="text-warning fs-5 text-nowrap">
            Teacher Name
          </th>
          <th scope="col" class="text-warning fs-5 text-nowrap">
            Academic Qualification
          </th>
          <th scope="col" class="text-warning fs-5 text-nowrap">
            Professional Qualification
          </th>
          <th scope="col" class="text-warning fs-5 text-nowrap">
            Post
          </th>
        </tr>
      </thead>
      <tbody>
        {table.map((el) => {
          return (
            <tr>
              <td>{el.fullName}</td>
              <td>{el.academicQualification}</td>
              <td>{el.professionalQualification}</td>
              <td>{el.post}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};


const principal = [
    'Dear all, ', 
    'Education is a basic requirement for a prosperous life. ',
    "", 
    'we all know that an excellent life means a life full of righteousness, discipline and sanctity.',
    "",
    'At Shristi English Medium School, we continuously strive to make the lives of all students successful.', 
    "",
    'I strongly believe that education is a collaborative effort that involves professional administrators, committed teachers and motivated students. We dedicate ourselves as professional administrators in creating a dynamic education program empowering the students in a global perspective.',
    "",
    'Science and technology, history and geography, mathematics and geometry, Fluency with languages etc… are essential aspects of education which continuously help in the progress of life but the school activities and curriculum are committed to enable the skill development of students so that they can develop holistically.',
    "",
    'We foster a positive spirit and believe in partnership between students, parents, teachers and support staff striving to create a milieu that sustains excellence.',
    ' Our distinction lies in the pursuit of high academic attainment through support, encouragement, praise and motivation.',
    "",
    'One educated will educate many thus a school that creates a strong foundation for students sets up a strong foundation for a students future.',
    "",
    'Lastly, I strongly feel that appropriate knowledge and appropriate learning will make our nation strong and powerful',
    "",
    'We at Shriti will always welcome each one of you with open arms to know your views so as to be the best school in town.',
    "",
    'Thank you.',
    "",
    'Mr. Sanjay Mistry', 
    'Principal', 
    'SEMS ENGLISH MEDIUM SCHOOL','Lambhvel.', 
]

export const Facilities = () => {
  const navigate = useNavigate();
  const [icon, setIcon] = useState(false);
  const [category, setCategory] = useState("Category1");

  useEffect(() => {
    AOS.init({ duration: 1500 });
  });

  const ReaadMore = (pera) =>{
    // console.log(children);
    const [isReadMore, setIsReadMore] =useState(true)
    return(
        <>
        <p class='text'>
            {isReadMore ? pera.map((el,i)=> { 
                    if(i < 4){
                        return (
                            <>
                            {el} 
                            <br />
                            </>
                        )
                    }
            
            }): pera.map((el)=> <>{el} <br/>  </>)}
        </p>
        <div class='text-center' onClick={()=>setIsReadMore(!isReadMore)}>

        <button class='btn m-auto p-0 m-0' >{isReadMore ?<i class="bi bi-chevron-compact-down fs-1"></i> : <i class="bi bi-chevron-compact-up fs-1"></i>}</button>
        </div>
        </>
    )
}
  return (
    <div>
      <div style={{ background: "linear-gradient( white 50%, #215395 0) " }}>
        <div
          data-aos="zoom-in-up"
          class="d-grid align-items-center justify-content-center p-5"
          style={{
            backgroundImage: `url(${require("../images/about/top.png")})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div>
            <p class="display-3 fw-bold">Faculty</p>
            <p class="fw-semibold">
              {" "}
              <span onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                Home
              </span>{" "}
              <span>|</span>{" "}
              <span
                onClick={() => navigate("/faculty")}
                style={{ cursor: "pointer" }}
              >
                Faculty
              </span>{" "}
            </p>
          </div>
        </div>

        <div
          class="col-11 m-auto"
          style={{ background: "linear-gradient( white 10%, #EFF6FF 0) " }}
        >
          <div class="row">
            <div class="col-lg-7 col-12 text-start ">
              <br />
              <br />
              <div class="col-11 m-auto">
                <p class="display-3 fw-bold mt-5" data-aos="fade-up">
                  From Principal Desk
                </p>
               {ReaadMore(principal)}

                {/* <p class='fs-5 my-3' data-aos="fade-up">As a new leader of Shristi English Medium School, Anand, Gujarat, I warmly welcome all of you to the website of our school and take pride in introducing you to the ethos, the conscientious drive and the inherent vision behind the school. SEMS has created a legacy of nurturing minds to dream the impossible. We are committed to empower them with the required skills, knowledge, capability, and the capacity to be truly successful, to become a good human being as well as a responsible global citizen.</p>
                            <div class='p-3 mt-4' style={{background:'#215395'}} data-aos="zoom-in">
                                <p class='text-white fs-5'>"Learning gives creativity, Creativity leads to thinking, Thinking provides knowledge, and Knowledge makes you GREAT." APJ Abdul Kalam.</p>
                            </div> */}
              </div>
            </div>
            <div class="col-lg-5 col-12 p-3 d-flex justify-content-end">
              <div
                style={{ maxWidth: "350px", height: "350px" }}
                data-aos="zoom-in"
              >
                <div>
                  <img
                    style={{
                      width: "100%",
                      height: "300px",
                      objectFit: "cover",
                      borderRadius: "20px",
                    }}
                    src={require("../images/other/principal.jpg")}
                    alt=""
                  />
                </div>
                <div
                  class="text-end d-flex rounded-5"
                  style={{
                    background: "#215395",
                    color: "white",
                    position: "relative",
                    top: "-30px",
                  }}
                >
                  <div class="text-start p-3">
                    <p class="fw-bold fs-5">Mr.Sanjay Mistry</p>
                    <p class="fs-5">Principal ( M.SC. , B. Ed.) </p>
                  </div>
                  {/* <div class='d-grid gap-1 rounded-5  p-3 mx-4 text-white' style={{background:'linear-gradient(180deg, #FDC830 0%, #F37335 100%)',marginTop:'-100px',height:'175px'}}>
                                    <i style={{cursor:'pointer'}}><AiOutlineInstagram/></i>
                                    <i style={{cursor:'pointer'}}><GrLinkedinOption/></i>
                                    <i style={{cursor:'pointer'}}><GrFacebookOption/></i>
                                    <i style={{cursor:'pointer'}}><BiPlusMedical/></i>  
                                </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div class='m-auto text-start' style={{padding:'20px 30px'}}>
                    <p data-aos="fade-up" class='fs-5'>Today, the role of a school is not only to pursue academic excellence but also to motivate and empower its students to be lifelong learners, critical thinkers, and productive members of an ever-changing global society. This can only be possible in a holistic, student-centric environment. SEMS strongly believes in activity based learning for grade KG to VIII std. where students show their keen interest and learn with great pleasure. For IX to XII, we go for application based teaching which is highly required for this generation to crack NEET/JEE or any competitive exam with flying colours.
                        During this pandemic time, SEMS has earned all the support and cooperation from each and every parent, guardian, student, faculty member at every step and I look forward to the same collaboration and support so that we embrace all the challenges with confidence, steadfastness and fortitude.
                        Stay well! Stay positive! Keep smiling!</p>
                </div> */}
          <br />
          <br />
        </div>
      </div>
      <div style={{ background: "#215395" }}>
        <br />
        <br />
        <div>
          <p class="display-3 fw-bold text-white " data-aos="fade-up">
            Our Faculty
          </p>
          {/* <p class='fs-5 text-white m-auto p-3' data-aos="fade-up" style={{maxWidth:'600px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore e</p> */}
          {/* <div class='col-10 m-auto mt-5' >
                    <div style={{display: 'flex', justifyContent: 'space-between',overflowX: 'auto',width:'100%', borderBottom:'0.5px solid #5289BF'}}> 
                        <button class='infraBtn text-nowrap' style={category == "Category1" ? {color:'white', borderBottom:'5px solid #FFCC29', border:'none'}:{color:'#5289BF', border:'none',}} onClick={()=> setCategory("Category1")}>Category 1</button>
                        <button class='infraBtn text-nowrap' style={category == "Category2" ? {color:'white', borderBottom:'5px solid #FFCC29', border:'none'}:{color:'#5289BF', border:'none',}} onClick={()=> setCategory("Category2")}>Category 2</button>
                        <button class='infraBtn text-nowrap' style={category == "Category3" ? {color:'white', borderBottom:'5px solid #FFCC29', border:'none'}:{color:'#5289BF', border:'none',}} onClick={()=> setCategory("Category3")}>Category 3</button>
                        <button class='infraBtn text-nowrap' style={category == "Category4" ? {color:'white', borderBottom:'5px solid #FFCC29', border:'none'}:{color:'#5289BF', border:'none',}} onClick={()=> setCategory("Category4")}>Category 4</button>
                        <button class='infraBtn text-nowrap' style={category == "Category5" ? {color:'white', borderBottom:'5px solid #FFCC29', border:'none'}:{color:'#5289BF', border:'none',}} onClick={()=> setCategory("Category5")}>Category 5</button> 
                    </div>
                </div> */}
        </div>

        <br />
        <div
          class="col-10 m-auto"
          data-aos="zoom-in-up"
          style={{ overflowX: "auto" }}
        >
          {table(table2)}
        </div>
        <br />
      </div>
    </div>
  );
};
