import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { useEffect } from 'react';
import Gall from './GallCopy';

 

const gallery1 =[ 
    {
      src:  `${require('../images/infrastructur/infrastructur.jpg')}`,
    },
    {
      src:  `${require('../images/infrastructur/infrastructur1.jpg')}`,
    },
    {
      src:  `${require('../images/infrastructur/infrastructur2.jpg')}`,
    },
    {
      src:  `${require('../images/infrastructur/infrastructur3.jpg')}`,
    },
    {
      src:  `${require('../images/infrastructur/infrastructur4.jpg')}`,
    },
    {
      src:  `${require('../images/infrastructur/infrastructur5.jpg')}`,
    },
    {
      src:  `${require('../images/infrastructur/infrastructur6.jpg')}`,
    },
    {
      src:  `${require('../images/infrastructur/infrastructur7.jpg')}`,
    },
    {
      src:  `${require('../images/infrastructur/infrastructur8.jpg')}`,
    },
    {
      src:  `${require('../images/infrastructur/infrastructur9.jpg')}`,
    },
    {
      src:  `${require('../images/infrastructur/infrastructur10.jpg')}`,
    },
    {
      src:  `${require('../images/infrastructur/infrastructur11.jpg')}`,
    },
    {
      src:  `${require('../images/infrastructur/infrastructur12.jpg')}`,
    },
    {
      src:  `${require('../images/infrastructur/infrastructur13.jpg')}`,
    },
    {
      src:  `${require('../images/infrastructur/infrastructur14.jpg')}`,
    },
    {
      src:  `${require('../images/infrastructur/infrastructur15.jpg')}`,
    },
    {
      src:  `${require('../images/infrastructur/infrastructur16.jpg')}`,
    },
    {
      src:  `${require('../images/infrastructur/infrastructur17.jpg')}`, 
      
    },
    {
      src:  `${require('../images/gallery/activity/activiti1.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti2.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti3.jpg')}`,
    }, 
    {
      src:  `${require('../images/gallery/activity/activiti5.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti6.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti7.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti8.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti9.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti10.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti11.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti12.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti13.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti14.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti15.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti16.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti17.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti18.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti19.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti20.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti21.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti23.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti24.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti25.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti26.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti27.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti28.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti29.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti30.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti31.jpg')}`,
    },
    {
      src:  `${require('../images/gallery/activity/activiti32.jpg')}`,
    },
]  
const gallery2 =[ 
 
  {
    src:  `${require('../images/infrastructur/infrastructur1.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur2.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur3.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur4.jpg')}`,
  }, 
] 
const gallery3 =[ 
   
  {
    src:  `${require('../images/infrastructur/infrastructur5.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur6.jpg')}`,
  }, 
]   
const gallery4 =[ 
   
  {
    src:  `${require('../images/infrastructur/infrastructur7.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur8.jpg')}`,
  },
   
]  
const gallery5 =[ 
  
  {
    src:  `${require('../images/infrastructur/infrastructur10.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur11.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur12.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur13.jpg')}`,
  },
  
]  
const gallery6 =[  
 
  {
    src:  `${require('../images/infrastructur/infrastructur10.jpg')}`,
  },
 
  {
    src:  `${require('../images/infrastructur/infrastructur12.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur13.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur14.jpg')}`,
  },  
  {
    src:  `${require('../images/infrastructur/infrastructur17.jpg')}`, 
    
  }
]  
const gallery7 =[  
  {
    src:  `${require('../images/infrastructur/infrastructur14.jpg')}`,
  }, 
  {
    src:  `${require('../images/infrastructur/infrastructur17.jpg')}`, 
    
  }
]  
const gallery8 =[  
  
  {
    src:  `${require('../images/infrastructur/pantry1.jpg')}`,
  },
  
  // {
  //   src:  `${require('../images/infrastructur/infrastructur11.jpg')}`,
  // },
  // {
  //   src:  `${require('../images/infrastructur/infrastructur12.jpg')}`,
  // },
  // {
  //   src:  `${require('../images/infrastructur/infrastructur13.jpg')}`,
  // },
  // {
  //   src:  `${require('../images/infrastructur/infrastructur14.jpg')}`,
  // },
  // {
  //   src:  `${require('../images/infrastructur/infrastructur15.jpg')}`,
  // },
  // {
  //   src:  `${require('../images/infrastructur/infrastructur16.jpg')}`,
  // },
  // {
  //   src:  `${require('../images/infrastructur/infrastructur17.jpg')}`, 
    
  // }
]  
const gallery9 =[ 
  {
    src:  `${require('../images/infrastructur/infrastructur.jpg')}`,
  },
   
  {
    src:  `${require('../images/infrastructur/infrastructur8.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur9.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur10.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur11.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur12.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur13.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur14.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur15.jpg')}`,
  },
  
  {
    src:  `${require('../images/infrastructur/infrastructur17.jpg')}`, 
    
  }
]  
const gallery10 =[ 
  
  {
    src:  `${require('../images/infrastructur/infrastructur9.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur10.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur11.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur12.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur13.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur14.jpg')}`,
  },
  {
    src:  `${require('../images/infrastructur/infrastructur15.jpg')}`,
  },
  
  {
    src:  `${require('../images/infrastructur/infrastructur17.jpg')}`, 
    
  }
]  
const gallery11 =[ 
  
  {
    src:  `${require('../images/gallery/activity/activiti1.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti2.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti3.jpg')}`,
  }, 
  {
    src:  `${require('../images/gallery/activity/activiti5.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti6.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti7.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti8.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti9.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti10.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti11.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti12.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti13.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti14.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti15.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti16.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti17.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti18.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti19.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti20.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti21.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti23.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti24.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti25.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti26.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti27.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti28.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti29.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti30.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti31.jpg')}`,
  },
  {
    src:  `${require('../images/gallery/activity/activiti32.jpg')}`,
  }, 
]  

 

const Gallery = () => {
    const navigate = useNavigate()
    const [srishtiClass , setSrishtiClass] = useState(" ")


    useEffect(()=>{
        AOS.init({duration: 1500}); 
    })
  return (
    <div> 
        <div class='d-grid align-items-center justify-content-center p-5' data-aos="zoom-in-up"  style={{backgroundImage:`url(${require("../images/about/top.png")})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',}} >
            <div>
                <p class='display-3 fw-bold'>Gallery</p>
                <p class='fw-semibold'> <span onClick={()=>navigate("/")} style={{cursor:'pointer'}} >Home</span> <span>|</span> <span onClick={()=>navigate("/activities")} style={{cursor:'pointer'}}>Gallery</span> </p>
            </div>
        </div>

        <br /><br /><br />
        <div class='col-11 m-auto'>
            <div class='infraBtnDiv'   >
                <div class='text-nowrap infraBtn'style={srishtiClass == " " ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass(' ')} >All</div> 
                <div class='text-nowrap infraBtn'style={srishtiClass == "Science Laboratories" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('Science Laboratories')} >Science Laboratories</div>
                <div class='text-nowrap infraBtn'style={srishtiClass == "Computer Laboratory" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('Computer Laboratory')} >Computer Laboratory</div> 
                <div class='text-nowrap infraBtn'style={srishtiClass == "Library" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('Library')} >Library</div>
                <div class='text-nowrap infraBtn'style={srishtiClass == "Multi-purpose area" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('Multi-purpose area')} >Multi-purpose area</div>
                <div class='text-nowrap infraBtn'style={srishtiClass == "AV room" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('AV room')} >AV room</div>
                <div class='text-nowrap infraBtn'style={srishtiClass == "Assembly Area" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('Assembly Area')} >Assembly Area</div>
                <div class='text-nowrap infraBtn'style={srishtiClass == "Pantry" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('Pantry')} >Pantry</div> 
                <div class='text-nowrap infraBtn'style={srishtiClass == "Creative Corner" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('Creative Corner')} >Creative Corner</div>
                <div class='text-nowrap infraBtn'style={srishtiClass == "Infirmary" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('Infirmary')} >Infirmary</div> 
                <div class='text-nowrap infraBtn'style={srishtiClass == "Activities" ? {borderBottom:'5px solid #F37335'}:{borderBottom:'.5px solid '}} onClick={()=>setSrishtiClass('Activities')} >Activities</div> 

            </div> 
            {
                srishtiClass == " " ? <Gall gallery={gallery1}/> : ''
            }
            {
                srishtiClass == "Science Laboratories" ? <Gall gallery={gallery2}/> : ''
            }
            {
                srishtiClass == "Computer Laboratory" ? <Gall gallery={gallery3}/> : ''
            }
            {
                srishtiClass == "Library" ? <Gall gallery={gallery4}/>  : ''
            }
            {
                srishtiClass == "Multi-purpose area" ?  <Gall gallery={gallery5}/> : ''
            }
            {
                srishtiClass == "AV room" ? <Gall gallery={gallery6}/>  : ''
            }
            {
                srishtiClass == "Assembly Area" ? <Gall gallery={gallery7}/>  : ''
            }
            {
                srishtiClass == "Pantry" ? <Gall gallery={gallery8}/> : ''
            }
            {
                srishtiClass == "Creative Corner" ?<Gall gallery={gallery9}/>  : ''
            }
            {
                srishtiClass == "Infirmary" ? <Gall gallery={gallery10}/>  : ''
            }
            {
                srishtiClass == "Activities" ? <Gall gallery={gallery11}/>  : ''
            } 
        </div>  
    </div>
  )
}

export default Gallery