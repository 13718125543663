import React from 'react'
import { useNavigate } from 'react-router-dom'
import { BiPlusMedical } from 'react-icons/bi';
import { GrFacebookOption, GrLinkedinOption } from 'react-icons/gr';
import { AiOutlineInstagram } from 'react-icons/ai';
import { OtherTrustees } from './MultiCarousel';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { useEffect } from 'react';
import { useState } from 'react';

const about = {
    otherTrustees:[
        {
            img:`${require('../images/about/opShinha.png')}`,
            name:'Dr O P Sinha',
            post:'Trustee',
        },
        {
            img:`${require('../images/about/piyaliJana.jpg')}`,
            name:'Ms Piyali Jana',
            post:'Trustee',
        },
        {
            img:`${require('../images/about/taruna.png')}`,
            name:'Mrs Taruna Patel',
            post:'Trustee',
        },
        {
            img:`${require('../images/about/gouravRoy.png')}`,
            name:'Shri Gaurav Roy',
            post:'Trustee',
        },
       
       
    ]
}

const peraMadhumita = [
    "Our School came into existence to emerge as “A school with a difference”. We aim to provide a perfect balance of academics, co-curricular activities and our own cultural heritage. Schooling should be a joyful experience in the life of every student and their parents too. Hence, at the school we make an effort to make these wonderful years of learning interesting, meaningful, enjoyable and truly memorable for each and every child.",
    "SEMS aims at holistic growth and development of its prized students. The School is genuinely concerned about the emotional and intellectual growth of its students. The activities framed in the curriculum will empower their self-esteem, self-awareness and self-confidence.",
    'Sharing ideas, analyzing situations and expressing them are essential skills that are inculcated at the School through experimental learning within the parameters of our system.',
    "We firmly believe that our students will grow strong wings to fly to different heights and spread the fragrance of SEMS everywhere. They will be ready to face the practical challenges of life.",
    "Though information about our School is available to you from various sources, it is my humble request to the parents and well-wishers to come and visit our School and experience the warmth, motivation and encouragement that is prevailing at our School atmosphere.",
    "We solicit your continued support for the progress of the School."
]

const peraSenGupta =[
    "“Student should possess all the qualities of a good human being besides fostering excellence in education. We should make an honest endeavor to fulfil the same”. ",
    " ",
    " ",
]
const peraAtanuJana=[
    "SEMS is nearing two decades of its prominent existence in April, 2023. We are proud to inform that several students have made their presence at professions such as Medicine, Engineering, Business and allied sectors, bringing name and fame to our School and in turn offered their homage to their teachers.  ",
    "We as Trustees have taken care to induct teachers who can serve as Educator as well as the Parent at the School premises. We have apt teacher to student ratio and our School ensures that each student is moulded to become a responsible ‘Social human being’ contributing to the growth of society, state, country and even in global scenario. I end with a prominent Quote related to education. ",
    <b> Tell me and I forget. Teach me and I remember. Involve me and I learn  Benjamin Franklin  </b>,
]

const prayasvin =[
    'Children have always and still fascinate me with all the things they do. There is certain sense of commitment in them and all the activities they do making them engrossed in the things, often loosing track of other happenings. I often thought that if this keenness towards things could be nurtured we could be looking at better, sharper and proactive kids in the future. My these thoughts led me to support the initiative taken by ‘Shristi Education Trust’, where the kids will not only get the best education but would also get a chance to develop and excel in other co-curricular and extra-curricular activities. We are looking at this initiative as a platform for the young ones to grow in the fields they have innate talents in or have an interest to learn.',
    'With the wide scope of activities embedded in the programs for the academic year, ensures overall development of the child. They get trained in soft skills and with robust events and training throughout the year, they surely will help the children reach their potential best.'
]

const otherTrustees = about.otherTrustees
const ReaadMore = (pera) =>{
    // console.log(children);
    const [isReadMore, setIsReadMore] =useState(true)
    return(
        <>
        <p class='text'>
            {isReadMore ?  pera[0] : pera.map((el)=> (<><p>{el} </p> <br /></> ))}
        </p>
        <div class='text-center' onClick={()=>setIsReadMore(!isReadMore)}>

        <button class='btn m-auto p-0 m-0' >{isReadMore ?<i class="bi bi-chevron-compact-down fs-1"></i> : <i class="bi bi-chevron-compact-up fs-1"></i>}</button>
        </div>
        </>
    )
}



 

const About = () => {
    const navigate = useNavigate()
    useEffect(()=>{
        AOS.init({duration: 1500}); 
    })
  return (
    <div>
        <div data-aos="zoom-in-up" class='d-grid align-items-center justify-content-center p-5' style={{backgroundImage:`url(${require("../images/about/top.png")})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',}} >
            <div>
                <p class='display-3 fw-bold'>About Us</p>
                <p class='fw-semibold'> <span onClick={()=>navigate("/")} style={{cursor:'pointer'}} >Home</span> <span>|</span> <span onClick={()=>navigate("/about")} style={{cursor:'pointer'}}>About</span> </p>
            </div>
        </div>

        <div class='row'>
            <div class='col-lg-6 col-12 p-3'>
                <div class='text-start m-auto mt-5'>
                    <div class='col-11 m-auto'> 
                        <p class="fw-bold fs-4"style={{color:'#F37335'}} data-aos="fade-up">ABOUT US</p>
                        <p class='display-3 fw-bold my-3' data-aos="fade-up"> Education without limits - in a<span class='text-success' >state-of-the-art campus!</span></p>
                        <p style={{color:'gray'}} data-aos="fade-up" class=''>Shristi English Medium school is a non-residential, co-educational and secular Institution.</p>
                        <p style={{color:'gray'}} data-aos="fade-up" class=''>The school campus is located in a congenial and eco-friendly surroundings, away from the ‘hustle and bustle’ of the city; befitting learning surroundings. The school has ‘State-of-art’ facilities to make learning an enjoyable experience.</p>
                    </div>
                </div>
            </div>
            <div class='col-lg-6 col-12 p-3'>
                <div class='col-12'>

                <img style={{width:'75%'}} data-aos="zoom-in" src={require("../images/about/aboutUs.png")} alt="" />
                </div>
            </div>  
        </div>

        <div class='row'>
            <div class='col-lg-6 col-12'>
                <div class='col-10'>

                    <img style={{width:'100%'}} data-aos="zoom-in" src={require("../images/about/visionMission.png")} alt="" />    
                </div>
            </div>
            <div class='col-lg-6 col-12 text-start d-grid align-items-center'>
                <div class='p-4'> 
                    <div> 
                        <p class='display-3 fw-bold' data-aos="fade-up">Vision and Mission</p>
                        <p  class='fs-4 my-4'style={{color:'gray'}} data-aos="fade-up">To create an environment to develop our children into life-long learners and become caring and compassionate members of the society.</p>
                    </div>
                    <div class='p-3 ' style={{background:'#215395'}}data-aos="zoom-in-up">
                        <div class='d-flex fs-5 text-start gap-3'><p style={{color:'#FFE65E'}}><i class="bi bi-star-fill"></i></p><p class='text-white'>To be a centre for excellence in education, maintaining the rich culture and values of our country.</p></div>
                        <div class='d-flex fs-5 text-start gap-3 mt-3'><p style={{color:'#FFE65E'}}><i class="bi bi-star-fill"></i></p><p class='text-white'>To train the present generation who will be morally and academically sound and excel in all their undertakings.</p></div>
                    </div>
                </div>
            </div>
        </div>
        <br />

        <div class='mt-5'> 
            <div class='row my-5'>
                <div class='col-lg-4 p-3'>
                    <div style={{maxWidth:'350px', margin:'auto'}} data-aos="fade-up">
                        <div>
                            <img style={{width:'100%',borderRadius:'25px',height:'350px'}} id='madhumitaJana' src={require("../images/home/MadhumitaJana.png")} alt="" />
                        </div>
                        <div class="text-end d-flex rounded-5" style={{background:'#215395',color:'white', position:'relative',top:'-30px'}} >
                            <div class='text-start p-3'>
                                <p class='fw-bold fs-5'>Madhumita Jana</p>
                                <p class='fs-5'>President, Shristi Education Trust, Anand</p>
                            </div> 
                            {/* <div class='d-grid gap-1 rounded-5  p-3 mx-4 text-white' style={{background:'linear-gradient(180deg, #FDC830 0%, #F37335 100%)',marginTop:'-100px',height:'175px'}}>
                                <i style={{cursor:'pointer'}}><AiOutlineInstagram/></i>
                                <i style={{cursor:'pointer'}}><GrLinkedinOption/></i>
                                <i style={{cursor:'pointer'}}><GrFacebookOption/></i>
                                <i style={{cursor:'pointer'}}><BiPlusMedical/></i>  
                            </div> */}
                        </div>
                    </div>
                </div>
                <div class='col-lg-8 col-11 m-auto'>
                    <div class='text-start m-5'>
                        <p class='display-3 fw-semibold'data-aos="fade-up">From <br /> President’s Desk</p>
                    </div> 
                    <div class='text-start   fs-5 p-3' style={{background:'#EFF6FF'}} data-aos="zoom-in-up">
                        
                        {ReaadMore(peraMadhumita)}
                    </div>
                </div> 
            </div>
       

            <div class='row flex-column-reverse flex-lg-row my-5'>
                <div class='col-lg-8 col-11 m-auto'>
                    <div class='text-end col-lg-11 col-12 '>
                        <p class='display-3 fw-semibold 'data-aos="fade-up">Messages <br /> from Trustee</p>
                    </div>
                            <br />
                    <div class='text-start  fs-5 p-3' style={{background:'#EFF6FF'}} data-aos="zoom-in-up">
                        <br />
                        {ReaadMore(prayasvin)} 
                    </div>
                </div> 
                <div class='col-lg-4 p-3'>
                    <div style={{maxWidth:'350px', margin:'auto'}} data-aos="fade-up">
                        <div>
                            <img  style={{width:'100%',borderRadius:'25px',height:'350px',objectFit:'cover'}} id='prayasvin' src={require("../images/home/Prayasvin.jpg")} alt="" />
                        </div>
                        <div class="text-end d-flex rounded-5" style={{background:'#215395',color:'white', position:'relative',top:'-30px'}} >
                            <div class='text-start p-3'>
                                <p class='fw-bold fs-5'>Mr. Prayasvin B. Patel</p>
                                <p class='fs-5'>Trustee</p>
                            </div> 
                            {/* <div class='d-grid gap-1 rounded-5  p-3 mx-4 text-white' style={{background:'linear-gradient(180deg, #FDC830 0%, #F37335 100%)',marginTop:'-100px',height:'175px'}}>
                                <i style={{cursor:'pointer'}}><AiOutlineInstagram/></i>
                                <i style={{cursor:'pointer'}}><GrLinkedinOption/></i>
                                <i style={{cursor:'pointer'}}><GrFacebookOption/></i>
                                <i style={{cursor:'pointer'}}><BiPlusMedical/></i>  
                            </div> */}
                        </div>
                    </div>
                </div> 
            </div>
        
            <div class='row my-5' >
                <div class='col-lg-4 p-3'>
                    <div style={{maxWidth:'350px', margin:'auto'}} data-aos="fade-up">
                        <div>
                            <img id='atanu' style={{width:'100%',borderRadius:'25px',height:'350px'}} src={require("../images/home/atanu.png")} alt="" />
                        </div>
                        <div class="text-end d-flex rounded-5" style={{background:'#215395',color:'white', position:'relative',top:'-30px'}} >
                            <div class='text-start p-3'>
                                <p class='fw-bold fs-5'>Dr. Atanu Jana</p>
                                <p class='fs-5'>Trustee</p>
                            </div> 
                            {/* <div class='d-grid gap-1 rounded-5  p-3 mx-4 text-white' style={{background:'linear-gradient(180deg, #FDC830 0%, #F37335 100%)',marginTop:'-100px',height:'175px'}}>
                                <i style={{cursor:'pointer'}}><AiOutlineInstagram/></i>
                                <i style={{cursor:'pointer'}}><GrLinkedinOption/></i>
                                <i style={{cursor:'pointer'}}><GrFacebookOption/></i>
                                <i style={{cursor:'pointer'}}><BiPlusMedical/></i>  
                            </div> */}
                        </div>
                    </div>
                </div>
                <div class='col-lg-8 col-11 m-auto'>
                    <div class='text-start m-5'>
                        <p class='display-3 fw-semibold'data-aos="fade-up">Messages <br /> from Trustee</p> 
                    </div> 
                    <div class='text-start  fs-5 p-3' style={{background:'#EFF6FF'}} data-aos="zoom-in-up">
                        <br />
                         {ReaadMore(peraAtanuJana)}
                    </div>
                </div> 
            </div>
      
        </div>

        <div>
            <p class='display-3 fw-bold mt-5'data-aos="fade-up">Other Trustees</p>
            {/* <p  class='m-auto p-2 my-3'style={{maxWidth:'600px'}} data-aos="fade-up">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore e</p> */}
            <div class='mt-5' style={{background:'linear-gradient(#ffffff 50%, #215395 50%)' }} >
                <div class='col-11 m-auto p-3' data-aos="zoom-in-up"> 
                    <OtherTrustees otherTrustees={otherTrustees}/>
                </div>
            </div>
        </div>

        <div style={{background:'#215395'}}>
            <div class='col-11 m-auto '>
                <br /><br />
                <p class='display-3 fw-semibold  text-white'data-aos="fade-up">Former Trustee</p> 
                <div class='row py-5 justify-content-around'>
                    <div class='col-md-3 col-sm-6 col-12 p-2 text-start' data-aos="zoom-in-up">
                        <div class='p-4'style={{borderTop:'5px solid #FDC830',background:'#134F8B',minHeight:'170px'}}>
                            <p class='text-white fw-bold fs-5'>Late Dr. Hrishikesh Jana</p>
                            <p class='text-white my-3' style={{fontSize:'14px'}}>Medical Practitioner (Hypnotherapist), Ahmedabad</p>
                        </div>
                    </div>
                    <div class='col-md-3 col-sm-6 col-12 p-2 text-start' data-aos="zoom-in-up">
                        <div class='p-4'style={{borderTop:'5px solid #FDC830',background:'#134F8B',minHeight:'170px'}}>
                            <p class='text-white fw-bold fs-5'>Late Col. A. K. Sen Gupta</p>
                            <p class='text-white my-3' style={{fontSize:'14px'}}>Ex-Army Officer, Corps of Signals</p>
                        </div>
                    </div>
                    <div class='col-md-3 col-sm-6 col-12 p-2 text-start' data-aos="zoom-in-up">
                        <div class='p-4'style={{borderTop:'5px solid #FDC830',background:'#134F8B',minHeight:'170px'}}>
                            <p class='text-white fw-bold fs-5'>Late Shri Laljibhai B. Darji </p>
                            <p class='text-white my-3' style={{fontSize:'14px'}}>Chartered Accountant, Darji & Associates, Vallabh Vidyanagar</p>
                        </div>
                    </div>
                    {/* <div class='col-md-3 col-sm-6 col-12 p-2 text-start' data-aos="zoom-in-up">
                        <div class='p-4'style={{borderTop:'5px solid #FDC830',background:'#134F8B',minHeight:'170px'}}>                            
                            <p class='text-white fw-bold fs-5'>Late Dr. Hrishikesh Jana (Ex-Trustee)</p>
                            <p class='text-white my-3' style={{fontSize:'14px'}}>Medical Practitioner (Hypnotherapist), Ahmedabad</p>
                        </div>
                    </div>  */}
                </div>
            </div>
        </div>  
    </div>
  )
}

export default About