import React, { useEffect, useState } from 'react'
import './style.css'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';

const Navbar = () => {
    // const [active, setActive] = useState('')
    useEffect(()=>{
        const nav = document.querySelector('.navbarMain');
        let lastScrollY = window.scrollY; 
        window.addEventListener('scroll',()=>{
          if(lastScrollY < window.scrollY){
            nav.classList.add('navbarMain--hidden');
          }else{
            nav.classList.remove('navbarMain--hidden');
          }  
          lastScrollY =window.scrollY 
        })  
    })

    const navigate = useNavigate()

    const routs = (el)=>{ 
        navigate(el) 
    }

    const location = useLocation();

    // Logic to determine if a link is active
    const isActiveLink = (link) => {
      return link === location.pathname;
    };

    // console.log(active);
  return (
    <div class='navbarMain ' style={{width:'100%'}}> 
        <div style={{width:'95%', margin:'auto'}}>
            <div class='d-flex align-items-center justify-content-between mainNav'>
                <div class='d-flex align-items-center p-2'>
                      <img onClick={()=>routs("/")} style={{height:'65px',cursor:'pointer'}} src={require("../images/home/logo.png")} alt="" />
                </div>
                <div class='d-flex gap-3 align-items-center'>
                    <div class='decktopNav'>
                        <ul class ='d-flex gap-5   m-0 mt-3' style={{listStyle:'none',fontSize:'14px',fontWeight:'500',color:'gray'}}>
                            <li> 
                               <p style={{cursor:'pointer'}} class=' '><span><i class="bi bi-telephone-fill text-danger"></i></span> <span>+91-9510212744</span></p> 
                            </li>
                            <li>
                               <p style={{cursor:'pointer'}} class=' '><span><i class="bi bi-clock text-danger"  ></i></span> <span class='mx-1'> Mon - Fri 08:00 AM - 02:30 PM </span></p> 
                            </li>
                            <li>
                               <p style={{cursor:'pointer',maxWidth:'250px'}}  class=' text-start'><span><i class="bi bi-geo-alt-fill text-danger"></i></span> <a href='https://goo.gl/maps/NhaZd9DMb3Ryg1Et7' target='_blank' style={{textDecoration:'none'}} >Survey No. 510/4, Near D. Girish & Co., Lambhvel Road, Anand.</a></p> 
                            </li>
                            <li>
                                <button class='btn ' id='button' onClick={()=>navigate("/admission")}>Admissions</button>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <button class='btn mobileNav' data-bs-toggle="offcanvas" href="#offcanvasHeader" role="button" aria-controls="offcanvasExample"><i class="bi bi-list"></i></button>
                    </div>

                    {/* mobile navbar start  */}
                    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasHeader" aria-labelledby="offcanvasHeaderLabel">
                        <div class="offcanvas-header">
                            <div>
                            <img style={{height:'50px',cursor:'pointer'}}  data-bs-dismiss="offcanvas" aria-label="Close" src={require("../images/home/logo.png")} alt="" onClick={()=>routs("/")} />
                            </div>
                            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <div class='d-grid gap-2'> 
                                <div> 
                                    <button class={isActiveLink('/') ? 'active anibtn anibtn-left fs-5 p-0 my-2' : 'anibtn anibtn-left fs-5 p-0 my-2'}   data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>routs("/")}><span  >Home</span> </button>
                                </div>
                                <div>
                                    <button class={isActiveLink('/about') ? 'active anibtn anibtn-left fs-5 p-0 my-2' : 'anibtn anibtn-left fs-5 p-0 my-2'}    data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>routs("/about")}><span  >About</span></button>
                                </div>
                                <div>
                                    <button class={isActiveLink('/infrastructure') ? 'active anibtn anibtn-left fs-5 p-0 my-2' : 'anibtn anibtn-left fs-5 p-0 my-2'}  data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>routs("/infrastructure")}><span   >Infrastructure</span></button>
                                </div>
                                <div>
                                    <button class={isActiveLink('/faculty') ? 'active anibtn anibtn-left fs-5 p-0 my-2' : 'anibtn anibtn-left fs-5 p-0 my-2'}  data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>routs("/faculty")}><span >Faculty</span></button>
                                </div>
                                <div>
                                    <button class={isActiveLink('/activities') ? 'active anibtn anibtn-left fs-5 p-0 my-2' : 'anibtn anibtn-left fs-5 p-0 my-2'}  data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>routs("/activities")}><span >Activities</span></button>
                                </div>
                                <div>
                                    <button class={isActiveLink('/contacts') ? 'active anibtn anibtn-left fs-5 p-0 my-2' : 'anibtn anibtn-left fs-5 p-0 my-2'}  data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=>routs("/contacts")}><span  >Contacts</span></button>
                                </div> 
                            </div>
                            <div>
                                <ul class ='d-grid gap-3 text-center  m-0 mt-3 p-0' style={{listStyle:'none',fontSize:'14px',fontWeight:'500',color:'gray'}}>
                                    <li>
                                    <p style={{cursor:'pointer'}} class=' '><span><i class="bi bi-telephone-fill text-danger"></i></span> <span>+91-9510212744</span></p> 
                                    </li>
                                    <li>
                                    <p style={{cursor:'pointer'}} class=' '><span><i class="bi bi-clock text-danger"  ></i></span> <span class='mx-1'> Mon - Fri 08:00 AM - 02:30 PM</span></p> 
                                    </li>
                                    <li>
                                    <p style={{cursor:'pointer'}} class='px-5'  ><span><i class="bi bi-geo-alt-fill text-danger"></i></span> <span >Survey No. 510/4, Near D. Grish & Co., Lambhvel road Anand.</span></p> 
                                    </li>
                                    <li>
                                        <button class='btn px-3 rounded-5 p-1 fw-semibold text-white' data-bs-dismiss="offcanvas" aria-label="Close" style={{background:'#215395'}} onClick={()=>navigate("/admission")}>Admissions</button>
                                    </li>
                                </ul>
                            </div>
                             
                        </div>
                    </div>
                    {/* mobile navbar end  */} 
                </div>
            </div>

            <hr class='decktopNav m-0'/>
            <div class='gap-4 decktopNav'>
                <div class='d-flex gap-4'> 
                 
                    <button class={isActiveLink('/') ? 'active anibtn anibtn-left fs-5 p-0 my-2' : 'anibtn anibtn-left fs-5 p-0 my-2'}   onClick={()=>routs("/")}> <span>Home</span> </button>
                    <button class={isActiveLink('/about') ? 'active anibtn anibtn-left fs-5 p-0 my-2' : 'anibtn anibtn-left fs-5 p-0 my-2'}   onClick={()=>routs("/about")}><span>About</span></button>
                    <button class={isActiveLink('/infrastructure') ? 'active anibtn anibtn-left fs-5 p-0 my-2' : 'anibtn anibtn-left fs-5 p-0 my-2'}   onClick={()=>routs("/infrastructure")}><span>Infrastructure</span></button>
                    <button class={isActiveLink('/faculty') ? 'active anibtn anibtn-left fs-5 p-0 my-2' : 'anibtn anibtn-left fs-5 p-0 my-2'}   onClick={()=>routs("/faculty")}><span>Faculty</span></button>
                    <button class={isActiveLink('/activities') ? 'active anibtn anibtn-left fs-5 p-0 my-2' : 'anibtn anibtn-left fs-5 p-0 my-2'}   onClick={()=>routs("/activities")}><span>Activities</span></button>
                    <button class={isActiveLink('/contacts') ? 'active anibtn anibtn-left fs-5 p-0 my-2' : 'anibtn anibtn-left fs-5 p-0 my-2'}   onClick={()=>routs("/contacts")}><span>Contacts</span></button>
                </div> 
            </div>
        </div>
    </div>
  )
}

export default Navbar