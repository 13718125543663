import React from 'react'
import ScrollToTop from 'react-scroll-to-top'; 
import AOS from 'aos';
import './style.css'
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';


const Footer = () => {

    const [contact,setContact]= useState({})
    const [emailValid , setEmailValid] = useState(false)


    const navigate = useNavigate()
    useEffect(()=>{
        AOS.init({duration: 1500}); 
    })

    const location = useLocation();

    const contactInfo = (e)=>{
        const {name,value}= e.target;
        setContact({...contact,[name]:value})
    }
    // console.log(contact);

    useEffect(()=>{
        if(contact?.Email?.includes('@')){
            setEmailValid(true)
        }else{
            setEmailValid(false)
        }
    },[contact])

    console.log(contact);

    const sendInfo =()=>{
        if(contact?.Name && contact?.Name != "" && 
        contact?.Email && contact?.Email != "" &&
        contact?.Message && contact?.Message != ""&&
        contact?.Class && contact?.Class != ""){
        if(emailValid){ 
                axios.post(`https://techelecon.net/shristiapi/joinnow.php`,contact)
                .then((res)=>{
                    // console.log(res);
                    if(res.data.responseStatus == true){
                        Swal.fire({
                          position: "center",
                          icon: "success",
                          title: `${res.data.responseMessage}`,
                          showConfirmButton: false,
                          timer: 2000,
                        })
                        setContact({
                            "Name" : '', 
                            'Email': '',
                            'Message':'',
                            'Class':''
    
                        })
                    }else{
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: `${res.data.responseMessage}`,
                            showConfirmButton: false,
                            timer: 2000,
                          })
                    }
                }).catch((err)=>{
                    console.log(err);
                })
            }else{
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: `Invalid email address (required @)`, 
                    showConfirmButton: false,
                    timer: 2000,
                  })
            }
        }else{
            Swal.fire({
                position: "center",
                icon: "error",
                title: `Fill all fields`,
                showConfirmButton: false,
                timer: 2000,
              })
        }
    }

    // Logic to determine if a link is active
    const isActiveLink = (link) => {
      return link === location.pathname;
    };
  return (
    <div style={{marginTop:'-2px', background:'#F2F7FD',height:'100%'}}>
        <ScrollToTop smooth top="20"/>
        <div  style={{background:'linear-gradient(#215395 50%, #F2F7FD 50%)' }}>
            <br /><br /><br />
            <div class='col-10 m-auto row   p-4' data-aos="zoom-in-up" style={{backgroundImage:`url(${require("../images/home/formBg.png")})`,backgroundSize:'100% 100%',borderRadius:'40px'}}>
                
                <div class='col-md-5 col-11 text-start'>
                    <p class='fs-1 fw-bold' >Join us <br /> and stay tuned!</p>
                </div>
                <div class='col-md-7 col-11'>
                    <div class='d-flex gap-3 flex-wrap row'>
                        <div class='col-md-5 col-12'> 
                        <input class=' rounded-5 form-control' type="text" placeholder='Name' name='Name'  onChange={contactInfo}  value={contact?.Name}/>
                        </div>
                        <div class='col-md-6 col-12'> 
                            <input class='rounded-5 form-control' type="text" placeholder='Email' name='Email' onChange={contactInfo} value={contact?.Email}  />
                            <label  class='text-start text-danger px-2'  style={emailValid ? {display:'none'}:{marginTop:'0',display:'block'}}>Invalid mail address (required@) </label>
                        </div>

                       <div class='col-md-5 col-12 text-start' style={{marginTop:'-20px'}}> 
                         <level class='px-2 text-start'  >Class</level>
                             <select class="form-select  rounded-4" aria-label="Default select example" value={contact?.Class} name='Class' onChange={contactInfo} >
                                <option selected>Select </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option> 

                            </select>
                        </div>
                        <div class='col-12'>
                            <textarea class='form-control rounded-3' value={contact?.Message}   id="" rows="4" placeholder='Message' name='Message' onChange={contactInfo}></textarea>
                        </div>
                    </div>
                </div> 
                <button class='btn my-3 fs-5' id='button' style={{width:'fit-content'}} onClick={sendInfo}><span ><i class="bi bi-arrow-right-circle-fill"></i></span><span class=' mx-2 m-0'>Join Now</span></button>

            </div>
        </div>
        <div>

            <div class='row col-11 m-auto align-items-start my-5'> 
                <div class='col-md-3 col-sm-6 col-11 text-start p-2 d-flex align-items-center justify-content-center' style={{margin:'0px auto'}}>
                    <img class='my-5' style={{width:'120px'}} src={require("../images/home/logo.png")} alt="" />
                    {/* <p class='py-2'>Vivamus porta efficitur nibh nec convallis. Vestibulum egestas eleifend justo. Ut tellus ipsum, accumsan</p> */}
                </div> 
                <div class='col-md-3 col-sm-6 col-11 text-start p-2' style={{margin:'0px auto'}}> 
                    <ul class =' d-grid gap-3  text-start  m-0 mt-3 p-0' style={{listStyle:'none',fontSize:'14px',fontWeight:'500'}}>
                        <li>
                            <p style={{cursor:'pointer',display:'flex', gap:'10px'}} class=' '><span><i class="bi bi-telephone-fill text-danger"></i></span> <span><div><p>Call</p><p style={{color:'gray'}}>+91-9510212744</p></div></span></p> 
                        </li>
                        <li>
                            <p style={{cursor:'pointer',display:'flex', gap:'10px'}} class=' '><span><i class="bi bi-clock text-danger"></i></span> <span><div><p>Work Time</p><p style={{color:'gray'}}>Mon - Fri 08:00 AM - 02:30 PM</p></div></span></p> 
                        </li>
                        <li>
                            <p style={{cursor:'pointer',display:'flex', gap:'10px'}} class=' '><span><i class="bi bi-geo-alt-fill text-danger"></i></span> <span><div><p>Address</p><a href='https://goo.gl/maps/NhaZd9DMb3Ryg1Et7' target='_blank' >Survey No. 510/4, Near D. Girish & Co., Lambhvel Road, Anand.</a></div></span></p> 
                        </li> 
                    </ul>      
                </div> 
                <div class='col-md-3 col-sm-6 col-11 text-start p-2' style={{margin:'0px auto'}}>
                    <div class=' '> 
                        <div>
                            <p class='fw-bold fs-5'>Quick Link</p>
                        </div>
                        <p class={isActiveLink('/') ? 'active anibtn anibtn-left fs-5 p-0 ' : 'anibtn anibtn-left fs-5 p-0 '} onClick={()=>navigate("/")}> <span>Home</span> </p>
                        <p class={isActiveLink('/about') ? 'active anibtn anibtn-left fs-5 p-0 ' : 'anibtn anibtn-left fs-5 p-0 '} onClick={()=>navigate("/about")}> <span> About</span></p>
                        <p class={isActiveLink('/infrastructure') ? 'active anibtn anibtn-left fs-5 p-0 ' : 'anibtn anibtn-left fs-5 p-0 '} onClick={()=>navigate("/infrastructure")}><span>Infrastructure</span></p>
                        <p class={isActiveLink('/faculty') ? 'active anibtn anibtn-left fs-5 p-0 ' : 'anibtn anibtn-left fs-5 p-0 '} onClick={()=>navigate("/faculty")}><span>Faculty</span></p>
                        <p class={isActiveLink('/activities') ? 'active anibtn anibtn-left fs-5 p-0 ' : 'anibtn anibtn-left fs-5 p-0 '} onClick={()=>navigate("/activities")}><span>Activities</span></p>
                        <p class={isActiveLink('/contacts') ? 'active anibtn anibtn-left fs-5 p-0 ' : 'anibtn anibtn-left fs-5 p-0 '} onClick={()=>navigate("/contacts")}><span>Contacts</span></p>  
                    </div>
                </div> 
                <div class='col-md-3 col-sm-6 col-11 text-start p-2' style={{margin:'0px auto'}}>
                    {/* <img src={require("../images/home/map.png")} alt="" /> */}
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d365.51124725647156!2d72.90294541688708!3d22.57451687956338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e4e2135a27df3%3A0x463c26023432e123!2sTwinkling%20Stars%20Kindergarten%20%26%20Shristi%20English%20Medium%20School!5e0!3m2!1sen!2sin!4v1682160085514!5m2!1sen!2sin" width="100%" height="250"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14735.542371550222!2d72.9434935!3d22.5833819!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e4e599b654991%3A0xe47f691e5d88ad2c!2sShristi%20English%20Medium%20School!5e0!3m2!1sen!2sin!4v1684501274982!5m2!1sen!2sin" width="100%" height="250" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                </div> 
            </div>

            <hr class='col-11 m-auto' />

            <div class='col-11 m-auto d-flex gap-2 align-items-center justify-content-between flex-wrap my-3'>
                {/* <div>
                    <p style={{color:'gray'}}>© Copyrights 2023 Hi-Labsolution All rights reserved</p>
                </div> */}
                <div class='d-flex'>
                    <a href="https://www.facebook.com/shritiems" target='_blank'> 
                    <button style={{border:'none', background:'none',color:'#0E6BA8'}}><i class="bi bi-facebook fs-3"></i></button>
                    </a>
                    {/* <button style={{border:'none', background:'none',color:'#0E6BA8'}}><i class="bi bi-linkedin fs-3"></i></button>
                    <button style={{border:'none', background:'none',color:'#0E6BA8'}}><i class="bi bi-instagram fs-3"></i></button> */}
                </div>
            </div>
        </div>

    </div>
  )
}

export default Footer