import React, { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { AlumniSpeaks } from './MultiCarousel';
import axios from 'axios';
import Swal from 'sweetalert2';
 
 
  

const alumniSpeaks =[
    { 
        name:'Vansh',
        speak:'High School memories cherished; amazing environment and supportive Principal.'
    },
    { 
        name:'Darshan Patel',
        speak:'As an ex-student of Shristi English Medium School, I Dr. Darshan Patel.'
    },
    { 
        name:'Khushi Patel',
        speak:'Shristi provided growth, valuable opportunities, exceptional teachers, and lifelong friends.'
    },
    { 
        name:'Shrey',
        speak:'Attending Shristi was an incredible experience with exceptional faculty and staff, great extracurricular, and unforgettable memories.'
    },
    { 
        name:'Deven Patel',
        speak:'Grateful for excellent education and connections at Shristi; dedicated teachers, supportive community, and memorable highly recommended.'
    },
    

]

const Contact = () => {

    const [contact,setContact]= useState({})
    const [emailValid , setEmailValid] = useState(false)

    const navigate = useNavigate()
    useEffect(()=>{
        AOS.init({duration: 1500}); 
    })


    const contactInfo = (e)=>{
        const {name,value}= e.target;
        setContact({...contact,[name]:value})
    }
    // console.log(contact);

    useEffect(()=>{
        if(contact?.Email?.includes('@')){
            setEmailValid(true)
        }else{
            setEmailValid(false)
        }
    },[contact])

    const sendInfo =()=>{
        if(contact?.Name && contact?.Name != "" &&
        contact?.Phone && contact?.Phone != "" &&
        contact?.Email && contact?.Email != "" &&
        contact?.Message && contact?.Message != ""){
        if(emailValid){ 
                axios.post(`https://techelecon.net/shristiapi/ContactUs.php`,contact)
                .then((res)=>{
                    // console.log(res);
                    if(res.data.responseStatus == true){
                        Swal.fire({
                          position: "center",
                          icon: "success",
                          title: `${res.data.responseMessage}`,
                          showConfirmButton: false,
                          timer: 2000,
                        })
                        setContact({
                            "Name" : '',
                            'Phone':'',
                            'Email': '',
                            'Message':''
                        })
                    }else{
                        Swal.fire({
                            position: "center",
                            icon: "error",
                            title: `${res.data.responseMessage}`,
                            showConfirmButton: false,
                            timer: 2000,
                          })
                    }
                }).catch((err)=>{
                    console.log(err);
                })
            }else{
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: `Invalid email address (required @)`, 
                    showConfirmButton: false,
                    timer: 2000,
                  })
            }
        }else{
            Swal.fire({
                position: "center",
                icon: "error",
                title: `Fill all filds`,
                showConfirmButton: false,
                timer: 2000,
              })
        }
    }

    
  return (
    <div>
        <div data-aos="zoom-in-up" class='d-grid align-items-center justify-content-center p-5' style={{backgroundImage:`url(${require("../images/about/top.png")})`,backgroundRepeat:'no-repeat',backgroundSize:'cover',}} >
            <div>
                <p class='display-3 fw-bold '>Contact Us</p>
                <p class='fw-semibold'> <span onClick={()=>navigate("/")} style={{cursor:'pointer'}} >Home</span> <span>|</span> <span onClick={()=>navigate("/contacts")} style={{cursor:'pointer'}}>Contact Us</span> </p>
            </div>
        </div>

        <div class='d-flex justify-content-end p-0 m-0'>
            <div class='col-lg-11 col-12 m-auto row'>
                <div class='col-md-6 col-11  '>
                    <p class='fs-1 fw-bold mt-5' data-aos="fade-up">Write to us</p>
                    <div class='d-grid gap-4 mt-5'> 
                        <input data-aos="fade-up" type="text" class='form-control rounded-4 p-2 px-3' placeholder='Name' value={contact?.Name} name='Name' onChange={contactInfo}/>
                        <input data-aos="fade-up" type="text" class='form-control rounded-4 p-2 px-3' placeholder='Email' value={contact?.Email} name='Email' onChange={contactInfo} />
                        <label data-aos="fade-up" class='text-start text-danger px-2'  style={emailValid ? {display:'none'}:{marginTop:'-20px',display:'block'}}  >Invalid email address (required @)</label>
                        <input data-aos="fade-up" type="number" class='form-control rounded-4 p-2 px-3' placeholder='Phone Number' value={contact?.Phone}  name='Phone' onChange={contactInfo} />
                        <textarea data-aos="fade-up" type="text" class='form-control rounded-4 p-2 px-3' placeholder='Message' value={contact?.Message}  name='Message' onChange={contactInfo} /> 
                        <div class='d-flex justify-content-end'data-aos="fade-up">
                            <button  onClick={sendInfo} class='btn  ' id='button' style={{width:'fit-content'}}><span ><i class="bi bi-arrow-right-circle-fill"></i></span><span class=' mx-2 m-0'>Send Now</span></button>
                        </div>
                    </div>
                </div>
                <div class='col-md-6 col-12 '>
                    <div class='col-10 m-auto '>
                        <p class='fs-1 fw-bold mt-5' data-aos="fade-up">Our Contact</p>
                        <div class='d-grid gap-4 mt-5'> 
                            <div class='d-flex gap-3 align-items-center' data-aos="fade-up">
                                <div>
                                    <img style={{width:'60px'}} src={require("../images/contact/call.png")} alt="" />
                                </div>
                                <div class='text-start'>
                                    <p class='fw-bold'>Call</p>
                                    <p style={{color:'gray'}}>+91-9510212744</p>
                                    <p style={{color:'gray'}}> inquiry@shristi.edu.in</p> 
                                </div>
                            </div>

                            <div class='d-flex gap-3 align-items-center' data-aos="fade-up" >
                                <div>
                                    <img style={{width:'60px'}} src={require("../images/contact/workTime.png")} alt="" />
                                </div>
                                <div class='text-start'>
                                    <p class='fw-bold'>Work Time</p>
                                    <p style={{color:'gray'}}>Mon - Fri 08:00 AM - 02:30 PM</p>
                                </div>
                            </div>

                            <div class='d-flex gap-3 align-items-center' data-aos="fade-up">
                                <div>
                                    <img style={{width:'60px'}} src={require("../images/contact/address.png")} alt="" />
                                </div>
                                <div class='text-start'>
                                    <p class='fw-bold'>Address</p>
                                    {/* <p style={{color:'gray'}}>+2 342 5446 67</p> */}
                                    <p style={{color:'gray'}}>Twinkling Stars Kindergarten & Shristi English Medium School,
                                    Survey No. 510/4, Near D. Girish & Co.,
                                    Lambhvel Road, Anand.</p> 
                                </div>
                            </div>
                                    <button data-aos="fade-up" class='btn ' id='button' style={{width:'fit-content'}}> <a href={`https://goo.gl/maps/Y8YbMmYJzJC26cNH9`} target="_blank" style={{color:'white'}}><span ><i class="bi bi-arrow-right-circle-fill"></i></span><span class=' mx-2 m-0'>Get Direction</span></a></button>

                                    


                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class='d-flex  mt-4 col-11 m-auto'> 
        <div class='col-lg-6 col-md-0'> 
        </div>
            <div class='rounded-5 p-4 text-start col-lg-6 col-md-9 col-12 m-auto'  style={{backgroundImage:`url(${require("../images/contact/bg.png")})`,backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}}>
                    <p class='fw-bold fs-2'>ALUMNI SPEAKS?</p>  
                    <AlumniSpeaks speak={alumniSpeaks} />
                    <p class='fs-5'style={{fontSize:'14px'}}>Registration form Here <span style={{fontSize:'12px'}}><i class="bi bi-chevron-right"></i><i class="bi bi-chevron-right"></i><i class="bi bi-chevron-right"></i></span></p>
            <button data-aos="fade-up" class='btn' id='button' style={{width:'fit-content', marginBottom:'-50px',marginLeft:'5%'}}><span ><i class="bi bi-arrow-right-circle-fill"></i></span><span class=' mx-2 m-0'onClick={()=>navigate('/admission')}>Join Now</span></button>
            </div>
        </div>


        <div class='mt-5' style={{background:'linear-gradient(#ffffff 50%,#215395 50%)' }}>
            <div class='col-10 m-auto mt-5' data-aos="zoom-in-up">
                {/* <img style={{width:'100%'}} src={require("../images/contact/map.png")} alt="" /> */}
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d365.51124725647156!2d72.90294541688708!3d22.57451687956338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e4e2135a27df3%3A0x463c26023432e123!2sTwinkling%20Stars%20Kindergarten%20%26%20Shristi%20English%20Medium%20School!5e0!3m2!1sen!2sin!4v1682160085514!5m2!1sen!2sin&zoom=nn" width="100%" height="450"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14735.542371550222!2d72.9434935!3d22.5833819!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e4e599b654991%3A0xe47f691e5d88ad2c!2sShristi%20English%20Medium%20School!5e0!3m2!1sen!2sin!4v1684501274982!5m2!1sen!2sin" width="100%" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>

    </div>
  )
}

export default Contact