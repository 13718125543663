import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import ScrollToTop from './ScrollToTop';
import {Routes,Route, useLocation} from 'react-router-dom' 
import About from './components/About';
import Infrastructure from './components/Infrastructure';
import { Facilities } from './components/Facilities';
import  Gallery  from './components/Gallery';
import Contact from './components/Contact';
import Admission from './components/Admission';
import { useEffect } from 'react';
import Gall from './components/Gall';


function App() {

  const { pathname, hash, key } = useLocation();

  useEffect(() => { 
    if (hash === '') {
      window.scrollTo(0, 0);
    } 
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);  
  return (
    <div className="App">
      <ScrollToTop/>
      <Navbar/>
      <div class='AllPage'> 
        <Routes> 
            <Route path="/"  element={<Home/>}/> 
            <Route path="/about"  element={<About/>}/> 
            <Route path="/infrastructure"  element={<Infrastructure/>}/>  
            <Route path="/faculty"  element={<Facilities/>}/>
            <Route path="/activities"  element={<Gallery/>}/>  
            <Route path="/contacts"  element={<Contact/>}/>  
            <Route path="/admission"  element={<Admission/>}/> 

        </Routes>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
